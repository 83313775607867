import { useEffect, useState } from "react";
import CanticoDomEditor, { ClipInfo, VerseInfo } from "../lib/CanticoDomEditor";
import { Box, Button, IconButton } from "@material-ui/core";
import { Delete, PlayArrow, Pause, ZoomIn, ZoomOut } from "@material-ui/icons";
import Wavesurfer from "wavesurfer.js";
import Regions, { Region, RegionParams } from "wavesurfer.js/src/plugin/regions";
import { setClipAndAlign } from "./utils";

const modalStyle = {
  position: "absolute",
  bgcolor: "background.paper",
  verticalAlign: "bottom",
  width: "100%",
};

const zoomFactor = 1.2;

export default function ClipEditor({
  song,
  clip,
  audio,
  /** Clips that exist for the audio file */
  clipRanges,
  setOpen,
  verseInfo,
  setWait,
}: {
  song: CanticoDomEditor;
  clip:
    | ClipInfo
    | {
        recordingId: string;
        avFileName: string;
        ending: boolean;
      };
  audio: HTMLAudioElement;
  clipRanges: ClipInfo[];
  setOpen: (open: boolean) => void;
  verseInfo: VerseInfo;
  setWait: (wait: boolean) => void;
}) {
  const [wavesurfer, setWavesurfer] = useState(undefined as Wavesurfer | undefined);
  const [currentRegion, setCurrentRegion] = useState(null as Region | null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [minZoomLevel, setMinZoomLevel] = useState(0);

  useEffect(() => {
    const regions: RegionParams[] =
      clipRanges.length === 0
        ? [{ end: audio.duration, id: "" }]
        : clipRanges.map((clip) => ({ start: clip.begin, end: clip.end, id: clip.id }));
    const div = document.querySelector("#wavesurfer");
    if (!div) {
      alert("Can not display Wavesurfer");
      return;
    }
    // Clear div of any previous wavesurfer instances (only a problem during
    // development)
    div.innerHTML = "";
    const wavesurfer = Wavesurfer.create({
      container: div as HTMLElement,
      plugins: [Regions.create({ dragSelection: true, regions })],
    });
    wavesurfer.load(audio);
    // Select the current region, if there is already a clip with a proper ID
    setCurrentRegion(wavesurfer.regions.list[(clip as ClipInfo).id]);
    wavesurfer.on("region-created", setCurrentRegion);
    wavesurfer.on("region-click", setCurrentRegion);
    wavesurfer.on("region-dblclick", (_region: Region) => _region.play(0));
    wavesurfer.on("play", () => setIsPlaying(true));
    wavesurfer.on("pause", () => setIsPlaying(false));
    wavesurfer.on("finish", () => setIsPlaying(false));
    wavesurfer.on("region-update-end", function (_region: Region) {
      // Ensure precise cuts between different regions
      // TODO: Write the modfied region borders of the changed adjacent regions
      // back to MEI clips
      const list = wavesurfer.regions.list;
      for (const region in list) {
        if (isOverlapped(_region.start, list[region])) {
          list[region].onResize(_region.start - list[region].end, "end");
        } else if (isOverlapped(_region.end, list[region])) {
          list[region].onResize(_region.end - list[region].start, "start");
        }
      }
    });
    wavesurfer.on("ready", () => {
      const zoomLevel = div.clientWidth / wavesurfer.getDuration();
      setZoomLevel(zoomLevel);
      setMinZoomLevel(zoomLevel);
    });
    setWavesurfer(wavesurfer);
    setCurrentRegion(wavesurfer.regions.getCurrentRegion());
  }, [audio, clipRanges, clip]);

  function isOverlapped(_time: number, _region: Region) {
    if (_time > _region.start && _time < _region.end) return true;
    else return false;
  }

  function splitRegion() {
    if (!wavesurfer) {
      return;
    }
    const currentTime = wavesurfer.getCurrentTime();
    const cursorIsInCurrentRegion =
      currentRegion && currentTime >= currentRegion.start && currentTime <= currentRegion.end;
    const region = cursorIsInCurrentRegion ? currentRegion : wavesurfer.regions.getCurrentRegion();
    if (!region || currentTime === region.start || currentTime === region.end) {
      // Nothing to split, really
      return;
    }

    const regionLeft = wavesurfer.addRegion({
      start: region.start,
      end: currentTime,
    });
    wavesurfer.addRegion({
      start: currentTime,
      end: region.end,
    });
    region.remove();
    setCurrentRegion(regionLeft);
  }

  function assignClip(region?: Region) {
    const [begin, end] = region ? [region.start, region.end] : [0, audio.duration];
    setClipAndAlign(song, clip, verseInfo.manifestationRef, begin, end);
    close();
  }

  function removeRegion() {
    currentRegion?.remove();
    wavesurfer && setCurrentRegion(wavesurfer.regions.getCurrentRegion());
  }

  function zoom(factor: number) {
    if (!zoomLevel) {
      return;
    }
    const newZoomLevel = Math.max(zoomLevel * factor, minZoomLevel);
    setZoomLevel(newZoomLevel);
    wavesurfer?.zoom(newZoomLevel);
  }

  function close() {
    wavesurfer?.stop();
    setOpen(false);
  }

  return (
    <Box sx={modalStyle}>
      {currentRegion && (
        <style scoped>
          {`
            region[data-id=${currentRegion.id}]:before {
              content: "";
              position: absolute;
              left: 2px;
              top: 0;
              width: calc(100% - 4px);
              height: 100%;
              background-color: rgba(255, 0, 0, .1);
            }
          `}
        </style>
      )}
      <div id="wavesurfer" />
      <IconButton title="play" onClick={() => wavesurfer?.playPause()}>
        {isPlaying ? <Pause /> : <PlayArrow />}
      </IconButton>
      <IconButton title="remove region" onClick={removeRegion}>
        <Delete />
      </IconButton>
      {/* TODO: Use material ui v5 and ContentCut icon */}
      <Button title="split region" onClick={splitRegion} variant="outlined">
        Cut at cursor
      </Button>
      <IconButton onClick={() => zoom(zoomFactor)}>
        <ZoomIn />
      </IconButton>
      <IconButton onClick={() => zoom(1 / zoomFactor)}>
        <ZoomOut />
      </IconButton>
      <Button onClick={close}>Cancel</Button>
      <Button
        onClick={() => {
          setWait(true);
          assignClip();
          setWait(false);
        }}
      >
        Use entire audio file
      </Button>
      {currentRegion && <Button onClick={() => assignClip(currentRegion)}>Use clip</Button>}
    </Box>
  );
}
