import { Octokit } from '@octokit/rest';
import config from "../config/config.json";

class FileSystemNode {
  repo: Repo;
  name: string;
  path: string;
  sha: string;
  size: number;
  parentFolder?: Folder;

  constructor(
    repo: Repo,
    { name, path, sha, size }: { name: string; path: string; sha: string; size: number },
    parentFolder?: Folder,
  ) {
    this.repo = repo;
    this.name = name;
    this.path = path;
    this.sha = sha;
    this.size = size;
    this.parentFolder = parentFolder;
  }
}

type Pointer = {
  version: string;
  oid: string;
  hashMethod: string;
  size: number;
};

/**
 * Parses the version, oid and size fields of an LFS pointer file
 */
function parsePointer(pointerFileContent: string): Pointer {
  let version: string | undefined;
  let oid: string | undefined;
  let hashMethod: string | undefined;
  let size: number | undefined;

  for (const line of pointerFileContent.split(/\n/)) {
    const [, key, value] = line.match(/^([^ ]+) (.*)$/) || [];
    switch (key) {
      case "version":
        version = value;
        continue;
      case "oid":
        [hashMethod, oid] = value.split(":");
        continue;
      case "size":
        size = parseInt(value);
        continue;
    }
  }

  if (version && hashMethod && oid && size) {
    return { version, hashMethod, oid, size };
  }

  throw new Error("Unrecognized LFS pointer file format:\n" + pointerFileContent);
}

export class File extends FileSystemNode {
  async rawContent() {
    // TODO: If this throws, we either have to renew the access token or reload
    // the repo
    const { data } = await this.repo.octokit.repos.getContent({
      owner: this.repo.owner,
      repo: this.repo.repo,
      path: this.path,
    });
    if (data instanceof Array) {
      // Should be unreachable, but just in case...
      throw new Error(this.path + " is a directory and can not be opened.");
    }
    if (data.type !== "file") {
      // Should also be unreachable
      throw new Error(`${this.path} is a ${data.type} and can not be opened`);
    }

    // TODO: "Cast" data to a proper type instead of using "as any"
    const encoding = (data as any).encoding;
    if (encoding !== "base64") {
      throw new Error("Unsupported encoding: " + encoding);
    }

    return [data, encoding];
  }

  async rawLfsContent(pointer: string | Pointer) {
    if (typeof pointer === "string") {
      pointer = parsePointer(pointer);
    }
    if (pointer.hashMethod !== "sha256") {
      throw new Error("Unsupported LFS hashMethod: " + pointer.hashMethod);
    }

    const proxyHost = config.githubProxyHost;
    if (!proxyHost) {
      throw new Error("Configuration error: No GitHub proxy host configured");
    }

    const response = await fetch(
      `https://${proxyHost}/cantico-git-lfs.php?` +
        new URLSearchParams({
          "access-token": this.repo.accessToken,
          oid: pointer.oid,
          size: pointer.size.toString(),
          url: `https://github.com/${this.repo.owner}/${this.repo.repo}.git/info/lfs/objects/batch`,
        }),
    );

    const bytes = new Uint8Array(await response.arrayBuffer());
    const chars = new Array(bytes.length);
    for (const byte of bytes) {
      chars.push(String.fromCharCode(byte));
    }

    return chars.join("");
  }

  async mp3DataUrl() {
    if (!this.name.match(/\.mp3$/)) {
      throw new Error(this.path + " is not an mp3 file");
    }

    let [data, encoding] = await this.rawContent();
    let content = (data as any).content;

    if ((data.content as string).startsWith(btoa("version https://git-lfs.github.com/spec/v1"))) {
      const pointerText = Buffer.from(content, encoding).toString("utf8");
      content = btoa(await this.rawLfsContent(pointerText));
      encoding = "base64";
    }

    const url = `data:audio/mp3;${encoding},${content.replace(/\n/g, "")}`;
    return url;
  }

  async text() {
    const [data, encoding] = await this.rawContent();
    return Buffer.from((data as any).content, encoding).toString("utf8");
  }

  async save(content: string, message: string) {
    // See https://codelounge.dev/getting-started-with-the-githubs-rest-api

    //git pull
    const commits = await this.repo.octokit.repos.listCommits({
      owner: this.repo.owner,
      repo: this.repo.repo,
    });

    const latestCommitSHA = commits.data[0].sha;

    // make changes
    const files = [
      {
        mode: "100644" as "100644",
        path: this.path,
        content,
      },
    ];

    // git add .
    const {
      data: { sha: treeSHA },
    } = await this.repo.octokit.git.createTree({
      owner: this.repo.owner,
      repo: this.repo.repo,
      tree: files,
      base_tree: latestCommitSHA,
    });

    // git commit -m
    const {
      data: { sha: newCommitSHA },
    } = await this.repo.octokit.git.createCommit({
      owner: this.repo.owner,
      repo: this.repo.repo,
      // TODO: Find out current user's name and email
      author: { name: "Cantico", email: "info@cantico.me" },
      tree: treeSHA,
      message,
      parents: [latestCommitSHA],
    });

    // git push origin HEAD
    await this.repo.octokit.git.updateRef({
      owner: this.repo.owner,
      repo: this.repo.repo,
      // TODO: Create a new branch and attempt to merge into main?
      ref: "heads/main",
      sha: newCommitSHA,
    });
  }
}

export class Folder extends FileSystemNode {
  async children(): Promise<FileSystemNode[]> {
    const data1 = await this.repo.octokit.repos.getContent({
      owner: this.repo.owner,
      repo: this.repo.repo,
      path: this.path,
    });
    const data = data1.data;

    if (!(data instanceof Array)) {
      throw new Error("Expected an array of files");
    }

    const children = [];
    for (const entry of data) {
      switch (entry.type) {
        case "file":
          children.push(new File(this.repo, entry, this));
          continue;
        case "dir":
          children.push(new Folder(this.repo, entry, this));
      }
    }
    return children;
  }

  async audioFiles() {
    return (await this.children()).filter((file) => file.name.match(/\.(mp3)$/));
  }
}

async function fetchFiles(folder: Folder): Promise<File[]> {
  const children = await folder.children();
  const promises = children
    .map(async (child) => (child instanceof Folder ? fetchFiles(child) : [child as File]))
    .flat();
  return (await Promise.all(promises)).flat();
}

export class Repo {
  owner: string;
  repo: string;
  accessToken: string;
  root?: Folder;
  octokit: Octokit;
  private mp3sByPath?: { [path: string]: File };
  private meiFilesByPath?: { [path: string]: File };

  constructor(accessToken: string, owner: string, repo: string) {
    this.owner = owner;
    this.repo = repo;
    this.accessToken = accessToken;
    this.octokit = new Octokit({ auth: accessToken });
  }

  async content() {
    if (!this.root) {
      this.root = new Folder(this, { name: "", path: "", sha: "", size: 0 });
    }
    return this.root;
  }

  private async fetchMp3sAndMeis() {
    this.mp3sByPath = {};
    this.meiFilesByPath = {};
    for (const file of await fetchFiles(await this.content())) {
      const extension = file.name.slice(-4).toLowerCase();
      switch (extension) {
        case ".mp3":
          this.mp3sByPath[file.path] = file;
          continue;
        case ".mei":
        case ".xml":
          this.meiFilesByPath[file.path] = file;
      }
    }
  }

  async getMp3sByPath() {
    // TODO: Check if there was an update in the repo
    if (this.mp3sByPath) {
      return this.mp3sByPath;
    }
    await this.fetchMp3sAndMeis();
    return this.mp3sByPath!;
  }

  async getMeisByPath() {
    // TODO: Check if there was an update in the repo
    if (this.meiFilesByPath) {
      return this.meiFilesByPath;
    }
    await this.fetchMp3sAndMeis();
    return this.meiFilesByPath!;
  }
}
