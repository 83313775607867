import React, { useState } from "react";
import { TextField } from '@material-ui/core'
import CanticoDomEditor from "../lib/CanticoDomEditor";

export default function MetadataEditor({ song }: { song: CanticoDomEditor }) {
  // TODO: Watch for changes in the DOM

  const meiHead = song.findFirst("//meiHead") as Element


  function HeaderField({ label, placeholder, xpath }: { label: string, placeholder?: string, xpath: string }) {
    const elements = song.findAll(xpath, meiHead)
    const element = elements[0];
    const [value, setValue] = useState(element?.textContent || "");

    if (elements.length !== 1) {
      return <div className="error">
        {`Precisely one element for ${label} has to be present in <meiHead> at path ` + xpath}
      </div>
    }

    return (
      <TextField
        fullWidth
        label={label}
        placeholder={placeholder || label}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          element.textContent = e.target.value
        }}
      />
    )
  }


  return (
    // TODO: Instead of the xpath property, delegate fetching the field in the
    // DOM to the CanticoDomEditor
    <div className="MetadataEditor leftsideBox">
      <HeaderField
        label="Title"
        xpath="fileDesc/titleStmt/title[@type='main']"
      />
      <HeaderField
        label="Original title"
        xpath="fileDesc/titleStmt/title[@type='originalTitle']"
      />
      <HeaderField
        label="Copyright"
        placeholder="Lyricist, Composer, Publisher"
        xpath="fileDesc/pubStmt/availability"
      />
      <HeaderField
        label="Song number"
        placeholder=""
        xpath="fileDesc/seriesStmt/identifier"
      />
      <HeaderField
        label="bpm"
        placeholder=""
        xpath="fileDesc/notesStmt/annot[@type='bpm']"
      />
    </div>
  )
}
