import { Aligner } from "./Aligner";
import CanticoDomEditor, { ClipInfo } from "./CanticoDomEditor";
import config from "../config/config.json";

const url = config.waloschekAlignmentUrl;

export class WaloschekAligner extends Aligner {
  audioDataUrl: string;

  constructor(song: CanticoDomEditor, clip: ClipInfo, audioDataUrl: string) {
    super(song, clip);
    this.audioDataUrl = audioDataUrl;
  }

  async createWhenElements(alignmentScore: string) {
    const body = new FormData();
    body.append("mei", new Blob([alignmentScore]));
    body.append("audio", new Blob([this.audioDataUrl]));
    body.append("start", this.clip.begin.toString());
    body.append("end", this.clip.end.toString());

    const response = await fetch(url, { body, method: "POST" });
    if (!response.ok) {
      throw new Error("Error fetching from alignment server:\n\n" + (await response.text()));
    }
    const result = (await response.json()) as { [id: string]: number };
    const entries = Object.entries(result).sort((a, b) => a[1] - b[1]);
    entries.push(["", this.clip.end]);

    let previousTime = -1;
    let currentIds: string[] = [];
    for (const [id, time] of entries) {
      if (time > previousTime && currentIds.length > 0) {
        const data = "#" + currentIds.join(" #");
        this.song.buildChildren(this.clip.clip, [
          ["when", { type: "on", data, absolute: previousTime.toString() }],
          ["when", { type: "off", data, absolute: time.toString() }],
        ]);
        currentIds = [];
        previousTime = time;
      }
      currentIds.push(id);
    }
  }
}
