import CanticoDomEditor, { ClipInfo } from "../lib/CanticoDomEditor";
import { Interval, index, matches } from "static-interval-tree";
import { VerovioAligner } from "../lib/VerovioAligner";
import { useCallback, useEffect, useState } from "react";
import { DynamicProperty } from "../lib/DynamicProperty";
import { validateXML } from "../submodule-builds/xmllint-wasm";

export async function setClipAndAlign(
  song: CanticoDomEditor,
  clipDescription: ClipInfo | { recordingId: string; avFileName: string; ending: boolean },
  manifestationRef: string,
  begin: number,
  end: number,
) {
  const newClipElement = song.setClip(clipDescription, manifestationRef, begin, end);
  const newClipInfo = !newClipElement
    ? // If there was nothing to change, `clipDescription` was already a proper
      // `ClipInfo` pointing to a proper `<clip>`
      (clipDescription as ClipInfo)
    : // Something changed and we have to wait for `song.clips` to reflect the
      // new state
      await new Promise<ClipInfo>((resolve) => {
        const id = song.getOrCreateId(newClipElement);
        // TODO: use `value` instead of `uncachedValue`, which still seems to
        // cause problems.
        song.clips.observe(() => resolve(song.clips.uncachedValue.byId[id]));
      });
  // Manifestations without @data pointing to encoded music have nothing to with
  if (song.manifestationsInfo.value.byRef[manifestationRef].hasAttribute("data")) {
    new VerovioAligner(song, newClipInfo).align();
  }
}

export type EventInterval = Interval & { ref: string };

/**
 * Creates a function that, given a time, returns the references to all events
 * sounding at that point of time. This relies on `<when>` elements inside the
 * provided `<clip>` element.
 *
 * TODO: Could we turn this into a dynamic property of MeiDomEditor? Right now
 * the use needs to take care of creating a new acive getter whenever the
 * `<when>` elements change. Or we abandon this method, sort the `<when>`
 * elements and do a binary search on them.
 */
export function createActiveEventGetter(clip: Element) {
  // The <when> elements represent a single point of time, but we need
  // intervals. This means we have to manage the unfinished intervals while
  // looping over <when> elements.
  const missingStarts = new Map<string, EventInterval>();
  const missingEnds = new Map<string, EventInterval>();
  const intervals = [];

  for (const when of clip.querySelectorAll("when")) {
    const time = parseFloat(when.getAttribute("absolute") || "");
    if (isNaN(time)) {
      throw new Error(
        "Invalid or missing @absolute attribute on <when> element " +
          (when.getAttribute("xml:id") || ""),
      );
    }
    let start = true;
    switch (when.getAttribute("type") || "") {
      case "on":
        break;
      case "off":
        start = false;
        break;
      default:
        throw new Error(
          "Invalid or missing @type attribute on <when> element " +
            (when.getAttribute("xml:id") || ""),
        );
    }
    const refs = (when.getAttribute("data") || "").split(/\s+/);
    if (refs.length === 0) {
      throw new Error(
        "Invalid or missing @data attribute on <when> element " + when.getAttribute("xml:id"),
      );
    }
    for (const ref of refs) {
      const openIntervals = start ? missingStarts : missingEnds;
      const matchingInterval = openIntervals.get(ref);
      if (matchingInterval) {
        matchingInterval[start ? "start" : "end"] = time;
        openIntervals.delete(ref);
        intervals.push(matchingInterval);
      } else {
        const interval: EventInterval = {
          ref,
          start: start ? time : NaN,
          end: start ? NaN : time,
        };
        (start ? missingEnds : missingStarts).set(ref, interval);
      }
    }
  }

  const danglingRefs = [...missingStarts.values(), ...missingEnds.values()].map(
    (interval) => interval.ref,
  );
  if (danglingRefs.length > 0) {
    throw new Error(
      "Missing <when> elements to define startpoints or endpoint for events:\n  " +
        danglingRefs.join("\n  "),
    );
  }

  const intervalTree = index(intervals);

  return (time: number) =>
    matches<EventInterval>(intervalTree, { start: time, end: time }).map(
      (interval) => interval.ref,
    );
}

export function useDynamicProperty<T, U>(
  property: DynamicProperty<T>,
  getter: () => U,
  onChangeObserved?: () => void,
): U {
  const [value, setValue] = useState(getter);
  const observer = useCallback(() => {
    setValue(getter);
    onChangeObserved && onChangeObserved();
  }, [getter, onChangeObserved]);
  useEffect(() => {
    property.observe(observer);
    return () => property.stopObserving(observer);
  });
  useEffect(() => setValue(getter), [getter]);

  return value;
}

/**
 * Formatting and canonicalization for nicer git diffs.
 *
 * The same normalization is available for scripts in the MEI data repo:
 * https://github.com/cantico-me/mei-data/blob/main/normalize.sh
 */
export async function normalizeXml(xml: string) {
  for (const normalization of ["format", "c14n"] as ("format" | "c14n")[]) {
    try {
      xml = (await validateXML({ xml, normalization })).normalized;
    } catch (e) {
      throw new Error(e as string);
    }
  }
  return xml;
}


export const frequentLanguages = ["de", "fr", "en"];

// Copied from https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export const isoLanguages = {
  aa: "Afar",
  ab: "Abkhazian",
  ae: "Avestan",
  af: "Afrikaans",
  ak: "Akan",
  am: "Amharic",
  an: "Aragonese",
  ar: "Arabic",
  as: "Assamese",
  av: "Avaric",
  ay: "Aymara",
  az: "Azerbaijani",
  ba: "Bashkir",
  be: "Belarusian",
  bg: "Bulgarian",
  bi: "Bislama",
  bm: "Bambara",
  bn: "Bengali",
  bo: "Tibetan",
  br: "Breton",
  bs: "Bosnian",
  ca: "Catalan, Valencian",
  ce: "Chechen",
  ch: "Chamorro",
  co: "Corsican",
  cr: "Cree",
  cs: "Czech",
  cu: "Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic",
  cv: "Chuvash",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  dv: "Divehi, Dhivehi, Maldivian",
  dz: "Dzongkha",
  ee: "Ewe",
  el: "Greek, Modern (1453–)",
  en: "English",
  eo: "Esperanto",
  es: "Spanish, Castilian",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  ff: "Fulah",
  fi: "Finnish",
  fj: "Fijian",
  fo: "Faroese",
  fr: "French",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Gaelic, Scottish Gaelic",
  gl: "Galician",
  gn: "Guarani",
  gu: "Gujarati",
  gv: "Manx",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  ho: "Hiri Motu",
  hr: "Croatian",
  ht: "Haitian, Haitian Creole",
  hu: "Hungarian",
  hy: "Armenian",
  hz: "Herero",
  ia: "Interlingua (International Auxiliary Language Association)",
  id: "Indonesian",
  ie: "Interlingue, Occidental",
  ig: "Igbo",
  ii: "Sichuan Yi, Nuosu",
  ik: "Inupiaq",
  io: "Ido",
  is: "Icelandic",
  it: "Italian",
  iu: "Inuktitut",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kg: "Kongo",
  ki: "Kikuyu, Gikuyu",
  kj: "Kuanyama, Kwanyama",
  kk: "Kazakh",
  kl: "Kalaallisut, Greenlandic",
  km: "Central Khmer",
  kn: "Kannada",
  ko: "Korean",
  kr: "Kanuri",
  ks: "Kashmiri",
  ku: "Kurdish",
  kv: "Komi",
  kw: "Cornish",
  ky: "Kirghiz, Kyrgyz",
  la: "Latin",
  lb: "Luxembourgish, Letzeburgesch",
  lg: "Ganda",
  li: "Limburgan, Limburger, Limburgish",
  ln: "Lingala",
  lo: "Lao",
  lt: "Lithuanian",
  lu: "Luba-Katanga",
  lv: "Latvian",
  mg: "Malagasy",
  mh: "Marshallese",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  na: "Nauru",
  nb: "Norwegian Bokmål",
  nd: "North Ndebele",
  ne: "Nepali",
  ng: "Ndonga",
  nl: "Dutch, Flemish",
  nn: "Norwegian Nynorsk",
  no: "Norwegian",
  nr: "South Ndebele",
  nv: "Navajo, Navaho",
  ny: "Chichewa, Chewa, Nyanja",
  oc: "Occitan",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "Ossetian, Ossetic",
  pa: "Punjabi, Panjabi",
  pi: "Pali",
  pl: "Polish",
  ps: "Pashto, Pushto",
  pt: "Portuguese",
  qu: "Quechua",
  rm: "Romansh",
  rn: "Rundi",
  ro: "Romanian, Moldavian, Moldovan",
  ru: "Russian",
  rw: "Kinyarwanda",
  sa: "Sanskrit",
  sc: "Sardinian",
  sd: "Sindhi",
  se: "Northern Sami",
  sg: "Sango",
  si: "Sinhala, Sinhalese",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  ss: "Swati",
  st: "Southern Sotho",
  su: "Sundanese",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  ti: "Tigrinya",
  tk: "Turkmen",
  tl: "Tagalog",
  tn: "Tswana",
  to: "Tonga (Tonga Islands)",
  tr: "Turkish",
  ts: "Tsonga",
  tt: "Tatar",
  tw: "Twi",
  ty: "Tahitian",
  ug: "Uighur, Uyghur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapük",
  wa: "Walloon",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  za: "Zhuang, Chuang",
  zh: "Chinese",
  zu: "Zulu",
} as { [lang: string]: string };

/**
 * Language list with the most frequent languages listed first
 */
export const sortedLanguages = [
  ...frequentLanguages,
  ...Object.keys(isoLanguages)
    .filter((lang) => frequentLanguages.indexOf(lang) < 0)
    .sort(),
];
