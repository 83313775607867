import { useCallback, useEffect, useState } from "react";
import ResizeDetector from "react-resize-detector";
import verovio from "verovio";
import newVerovio from "../lib/Verovio";
import CanticoDomEditor from "../lib/CanticoDomEditor";

export default function Verovio({
  song,
  focusedMeiId,
  setFocusedMeiId,
  highlightElements,
}: {
  song?: CanticoDomEditor;
  focusedMeiId: string;
  setFocusedMeiId: (id: string) => void;
  highlightElements: (refs: string[]) => void;
}) {
  const [vrvToolkit, setVrvToolkit] = useState(undefined as undefined | verovio.toolkit);
  const [svg, setSvg] = useState("");
  const [pageWidth, setPageWidth] = useState(undefined as undefined | number);

  const render = useCallback(() => {
    const pages = [];
    const pageCount = vrvToolkit!.getPageCount();
    for (let i = 1; i <= pageCount; i++) {
      pages.push(vrvToolkit!.renderToSVG(i));
    }
    setSvg(pages.join("\n"));
  }, [setSvg, vrvToolkit]);

  const options = useCallback(
    () => ({
      systemMaxPerPage: 1,
      unit: 6,
      adjustPageHeight: true,
      footer: "none" as "none",
      header: "none" as "none",
      pageWidth: pageWidth && pageWidth * 2,
      svgViewBox: true,
      svgHtml5: true,
    }),
    [pageWidth],
  );

  useEffect(() => {
    (async function init() {
      setSvg("Loading Verovio...");
      const vrvToolkit = await newVerovio();
      vrvToolkit.setOptions(options());
      setVrvToolkit(vrvToolkit);
      setSvg("Verovio ready.");
    })();
  }, [setVrvToolkit, options]);

  useEffect(() => {
    song && vrvToolkit && vrvToolkit.loadData(song.serialize()) && render();
  }, [render, song, vrvToolkit]);

  useEffect(() => {
    focusedMeiId && highlightElements([`[data-id=${focusedMeiId}]`]);
  }, [focusedMeiId, highlightElements]);

  const onClick = useCallback(
    (e) => {
      let clickedElement = (e.target as Element).closest("[data-id]");
      while (clickedElement) {
        if (!clickedElement?.closest("svg")) {
          // The closest element with a @data-id attribute is outside the Verovio
          // page
          return;
        }
        const clickedId = clickedElement.getAttribute("data-id");
        if (clickedId && song?.findFirst(`//*[@xml:id=$clickedId]`, undefined, { clickedId })) {
          setFocusedMeiId(clickedId);
          break;
        }
        clickedElement = clickedElement.parentElement?.closest("[data-id]") || null;
      }
    },
    [setFocusedMeiId, song],
  );

  return (
    <>
      <ResizeDetector handleWidth onResize={setPageWidth} />
      <div className="Verovio" dangerouslySetInnerHTML={{ __html: svg }} onClick={onClick} />
    </>
  );
}
