import { v4 as uuidv4 } from "uuid";
import NamespaceResolver from "./NamespaceResolver";
import { DynamicPropertyClassFactory, DynamicPropertyClass } from "./DynamicProperty";
import fontoxpath from "fontoxpath";
import globals from "./globals";
// For some reason, this does not work:
//   import { parser, serializer, MutationObserver } from "./globals"
const { parser, serializer, MutationObserver } = globals;


export type NodeNameTemplate = string
export type TextNodeTemplate = string
export type AttributeTemplates = {
  [key: NodeNameTemplate]: string | null
}
export type ElementTemplate = [
  NodeNameTemplate, AttributeTemplates, ChildTemplates
] | [
  NodeNameTemplate, AttributeTemplates
] | [
  NodeNameTemplate, ChildTemplates
] | [
  NodeNameTemplate
]
export type ChildTemplates = TextNodeTemplate | (ElementTemplate | TextNodeTemplate)[]

/**
 * XPath variable declarations as supported by Fontoxpath
 */
type XPathVars = { [variableName: string]: any }


const meiTemplate = ["mei", { meiversion: "4.0.0" }, [
  ["meiHead", [
    ["fileDesc", [
      ["titleStmt", [
        ["title"],
      ]],
      ["pubStmt"],
    ]],
  ]],
  ["music", [
    ["body", [
      ["mdiv", [
        ["score", [
          ["scoreDef", { "meter.unit": "4", "meter.count": "4" }, [
            ["staffGrp", [
              ["staffDef", { n: "1", lines: "5", "clef.shape": "G", "clef.line": "2" }]
            ]],
          ]],
          ["section", { label: "verse" }, [
            ["measure", { n: "1" }, [
              ["staff", { n: "1" }, [
                ["layer", { n: "1" }, [
                  ["mrest"]
                ]],
              ]],
            ]],
          ]],
        ]],
      ]],
    ]],
  ]],
]] as ElementTemplate;


/**
 * Provides business logic for editing MEI
 */
export class MeiDomEditor {
  static readonly MEI_NS = "http://www.music-encoding.org/ns/mei";

  readonly dom: Document;
  resolve = new NamespaceResolver({
    // Make MEI the default namespace
    "": MeiDomEditor.MEI_NS,
    "mei": MeiDomEditor.MEI_NS,
  });
  // TODO: Use native XPath when in the browser? Should be much faster, but is
  // restricted to XPath 1.
  fontoxpathResolver = { namespaceResolver: this.resolve.prefix };
  private dirtyObserver: MutationObserver | undefined;
  private idObserver: MutationObserver | undefined;

  DynamicProperty: DynamicPropertyClass;

  /**
   * @param mei The MEI DOM, an XML string to be parsed, or a template that will
   * be converted to an MEI DOM.
   */
  constructor(mei?: Document | string | ElementTemplate) {
    this.dom = parser.parseFromString("<dummy/>", "application/xml");
    this.load(mei || meiTemplate);
    this.DynamicProperty = DynamicPropertyClassFactory(this);
  }


  load(mei: Document | string | ElementTemplate) {
    let doc: Element;
    if (typeof mei === "string") {
      doc = parser.parseFromString(mei, "application/xml").documentElement;
      // <parsererror> is the stupidest way of signalling an error, but that's
      // how it is.
      // It does not suffice to check if the root element is O.K. (as we do it
      // below) because the Chrome parser will happily parse the entire XML and
      // insert some <parsererror> elements where it found errors. That's not an
      // issue with Firefox and jsdom.
      const parsererror = doc.querySelector("parsererror");
      if (parsererror) {
        throw new Error(
          // We want to skip all the extra text that browsers might add before
          // and after the actual error message with the line number. We're
          // guessing how that line look like. And we hope there is no
          // additional markup that splits the info into multiple elements.
          this.findFirst(".//text()[matches(., '[Ll]ine[ :]+[0-9]')]", parsererror)
            ?.nodeValue || "Malformed XML"
        );
      }
    } else if (mei instanceof Array) {
      doc = this.buildNode(mei) as Element;
    } else {
      doc = mei.documentElement;
    }

    if (doc.namespaceURI !== MeiDomEditor.MEI_NS || doc.tagName !== "mei") {
      throw new Error("Can only load MEI documents");
    }

    this.dom.replaceChild(doc, this.dom.documentElement);
    this.dirty = false;
  }


  /**
   * Upon setting this flag, any missing IDs will immediately be added to the
   * document. In a browser environment, any new elements that are added later
   * will also automatically get IDs.
   */
  set autoAddIds(autoAdd: boolean) {
    if (!autoAdd) {
      if (this.idObserver) {
        this.idObserver.disconnect();
        this.idObserver = undefined;
      }
    } else if (!this.idObserver) {
      this.addIds();
      // Add IDs to "future" elements
      this.idObserver = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          for (const addedNode of mutation.addedNodes) {
            if (addedNode.nodeType === addedNode.ELEMENT_NODE) {
              this.addIds(addedNode as Element);
            }
          }
        }
      });
      this.idObserver.observe(this.dom, { childList: true, subtree: true });
    }
  }

  get autoAddIds() { return this.idObserver !== undefined; }


  /**
   * Is true if the document was modifed.
   */
  get dirty() {
    return this.dirtyObserver === undefined;
  }

  /**
   * The dirty flag can be reset, e.g. after the document was saved.
   */
  set dirty(value: boolean) {
    if (value === this.dirty) {
      return;
    }
    if (value === true) {
      this.stopDirtyObserver();
    } else {
      this.dirtyObserver = new MutationObserver(this.stopDirtyObserver);
      this.dirtyObserver.observe(this.dom, { childList: true, attributes: true, subtree: true });
    }
  }


  private stopDirtyObserver() {
    this.dirtyObserver?.disconnect();
    this.dirtyObserver = undefined;
  }


  findAll(xpath: string, context?: Node, variables?: XPathVars): Node[] {
    return fontoxpath.evaluateXPathToNodes(
      xpath, context || this.dom, null, variables, this.fontoxpathResolver
    );
  }


  findFirst(xpath: string, context?: Node, variables?: XPathVars): Node | null {
    return fontoxpath.evaluateXPathToFirstNode(
      xpath, context || this.dom, null, variables, this.fontoxpathResolver
    );
  }


  /**
   * Turns a "convenience" template (which can omit the attributes and declare
   * content as either an array of children or just a string if the only content
   * is text) into a "strict" template that is always a triple of node name,
   * attributes and child template array.
   */
  private normalizeTemplate(template: ElementTemplate): [string, AttributeTemplates, ChildTemplates] {
    const [tagName, template1, template2] = template;
    // template1 could be an Object for attributes, or an array or a string for
    // content
    const hasAttributes = (typeof template1 === "object") && !(template1 instanceof Array);
    if (hasAttributes && typeof template2 === "object") {
      // Template is already a "strict" template
      return template as [string, AttributeTemplates, ChildTemplates];
    }

    const attributes = hasAttributes ? template1 : {};
    const contentTemplate = hasAttributes ? template2 : template1;

    switch (typeof contentTemplate) {
      case "string":
        return [tagName, attributes, [contentTemplate]];
      case "object":
        return [tagName, attributes, contentTemplate];
      case "undefined":
        return [tagName, attributes, []];
      default:
        throw new Error("Content template can not be of type " + typeof contentTemplate);
    }
  }


  /**
   * Re-arranges attributes and content of `element` to match the structure of
   * the templates. No elements or attributes are removed or modified. Only
   * missing attributes and elements are added, and positions are changed
   * according to the template.
   *
   * @param element If omitted, defaults to the document element.
   */
  adaptToTemplate(template: ElementTemplate, element?: Element) {
    element = element || this.dom.documentElement;
    const [, attributes, contentTemplates] = this.normalizeTemplate(template);

    this.adaptAttributesToTemplate(element, attributes);

    const templateChildPairs = this.templateChildPairs(element, contentTemplates);
    let precedingNode: Node | undefined;
    for (let [template, child] of templateChildPairs) {
      if (!child) {
        child = this.buildNode(template);
      } else if (typeof template === "string") {
        if (child.textContent === "") {
          child.nodeValue = template;
        }
      } else {
        this.adaptToTemplate(template, (child as Element));
      }
      // Now move the child to the proper position
      const followingNode = precedingNode ? precedingNode.nextSibling : element.firstChild;
      precedingNode = element.insertBefore(child, followingNode);
    }
  }


  /**
   * Copies attributes of the template to the element, unless an attribute of
   * that name already exists. If the attributes value in the template is
   * `null`, the attribute is skipped.
   */
  private adaptAttributesToTemplate(element: Element, attributes: AttributeTemplates) {
    for (const name in attributes) {
      const value = attributes[name];
      if (value === null) {
        continue;
      }
      const { localName, attributeNamespaceURI } = this.resolve.nodeName(name);
      if (!element.hasAttributeNS(attributeNamespaceURI, localName)) {
        element.setAttributeNS(attributeNamespaceURI, localName, value);
      }
    }
  }


  /**
   * Looks at the structure of `element`'s children and the desired structure
   * described in the template. Tries to associate an existing node with each
   * template element as a pair.
   */
  private templateChildPairs(
    element: Element, contentTemplates: ChildTemplates
  ): ([ElementTemplate, Element?] | [string, Text?])[] {
    // Find matching nodes we can align the template nodes with
    const templateChildPairs = Array(contentTemplates.length);
    // We need to make sure that we don't associate the same node with more than
    // one template object. Therefore we track which nodes have already been
    // "consumed".
    const consumedNodes = new Set<Node>();

    for (let i = 0; i < contentTemplates.length; i++) {
      const template = contentTemplates[i];
      if (typeof template === "string") {
        const onlyTextChild = (
          element.childNodes.length === 1
          && element.firstChild?.nodeType === element.TEXT_NODE
          && element.firstChild
        ) || undefined;
        templateChildPairs[i] = [template, onlyTextChild];
        continue;
      }

      const normalizedTemplate = this.normalizeTemplate(template);
      const [nodeName] = normalizedTemplate;
      const matchingNode = this.findAll(nodeName, element).find(e => !consumedNodes.has(e));
      matchingNode && consumedNodes.add(matchingNode);
      templateChildPairs[i] = [normalizedTemplate, matchingNode];
    }

    return templateChildPairs;
  }


  getOrCreateChild(parent: Element, template: ElementTemplate): Element {
    const qname = this.resolve.nodeName(template[0]).nodeName;
    const existingChild = this.findFirst(qname, parent);
    return (existingChild || parent.appendChild(this.buildNode(template))) as Element;
  }


  buildNode(template: ElementTemplate | string): Element | Text {
    if (typeof template === "string") {
      return this.dom.createTextNode(template);
    }

    const [tagName, attributes, contentTemplates] = this.normalizeTemplate(template);
    const { nodeName, elementNamespaceURI } = this.resolve.nodeName(tagName);

    const element = this.dom.createElementNS(elementNamespaceURI, nodeName);
    this.addAttributes(element, attributes);
    this.buildChildren(element, contentTemplates);

    return element;
  }


  buildChildren(parent: Element, childTemplates: ChildTemplates) {
    if (typeof childTemplates === "string") {
      parent.appendChild(this.dom.createTextNode(childTemplates));
      return;
    }

    for (const childTemplate of childTemplates) {
      if (childTemplate instanceof Object) {
        parent.appendChild(this.buildNode(childTemplate));
      } else {
        parent.appendChild(this.dom.createTextNode(childTemplate));
      }
    }
  }


  addAttributes(element: Element, attributes: AttributeTemplates) {
    for (const name in attributes) {
      const value = attributes[name];
      if (value !== null) {
        const { nodeName, localName, attributeNamespaceURI } = this.resolve.nodeName(name);
        // Do not trigger mutation observers if not necessary
        // (Note: hasAttributeNS needs localName, setAttributeNS qualified name)
        if (!element.hasAttributeNS(attributeNamespaceURI, localName)) {
          element.setAttributeNS(attributeNamespaceURI, nodeName, value);
        }
      }
    }
  }


  remove(node: Node) {
    node.parentNode?.removeChild(node);
  }


  /**
   * Adds `@xml:id` attributes to the element and all of its descendants.
   */
  addIds(node?: Element | Document) {
    // TODO: This selector will skip elements that have an id attribute in a
    // namespace that is not the xml:* namespace
    for (const element of this.findAll("descendant-or-self::*[not(@xml:id)]", node || this.dom)) {
      this.getOrCreateId(element as Element);
    }
  }


  getOrCreateId(element: Element): string {
    let id = element.getAttribute("xml:id");
    if (id === null) {
      id = "u" + uuidv4();
      element.setAttributeNS(NamespaceResolver.XML_NS, "xml:id", id);
    }
    return id;
  }


  /**
   * Clones an element and all of its children. Inserts the clone at the target
   * node at the specified position.
   */
  cloneTo(element: Element, target: Element, position: InsertPosition) {
    const clone = element.cloneNode(true) as Element;
    for (const elementWithId of this.findAll("descendant-or-self::*[@xml:id]", clone) as Element[]) {
      elementWithId.removeAttribute("xml:id");
      if (this.autoAddIds) {
        this.getOrCreateId(elementWithId);
      }
    }
    target.insertAdjacentElement(position, clone);
  }


  observe(nodes: Node[] | string[], callback: MutationCallback, options?: MutationObserverInit): MutationObserver[] {
    if (typeof nodes[0] === "string") {
      const xpaths = nodes as string[];
      nodes = [] as Node[];
      for (const xpath of xpaths) {
        for (const result of this.findAll(xpath)) {
          nodes.push(result);
        }
      }
    }

    let observers: MutationObserver[] = []
    for (const node of nodes as Node[]) {
      const observer = new MutationObserver(callback);
      observer.observe(node, options);
      observers.push(observer);
    }
    return observers;
  }


  /**
   * @param element When calling without argument, the entire document is
   * serialized. Optional arguments are either an XPath string or an Element.
   */
  serialize(element?: Element | string): string {
    if (typeof element === "string") {
      element = this.findFirst(element) as Element;
      if (!element) {
        return "";
      }
    }
    return serializer.serializeToString(element || this.dom);
  }
}