import { MeiDomEditor, ElementTemplate } from "./MeiDomEditor";

type SongInfo = {
  /**
   * A sequence of sections as they should be displayed/performed
   */
  form: VerseInfo[],
  /**
   * `<section>`/verseNumber combinations that are not referenced by any
   * `<manifestation>`
   */
  orphanedVerses: { sectionInfo: SectionInfo, verseNumber: string | null }[],
  /**
   * `<manifestation>`s pointing to non-existent `<section>`s or verse numbers.
   */
  danglingManifestations: Element[],
  /**
   * Label attributes that are used by more than one section and therefore lead
   * to them being indinstinguishable when displayed in the UI.
   */
  duplicateSectionLabels: string[],
};


type SectionInfo = {
  section: Element;
  id: string;
  label: string;
  labelIsUnique: boolean;
  verseNumbers: Set<string>;
};

export type VerseInfo = {
  manifestation: Element;
  /**
   * `undefined` if the pointer `manifestation/@data` is dangling or the
   * manifestation is not notated (e.g. intro, interlude or outro).
   */
  label: string;
  sectionInfo?: SectionInfo;
  /**
   * The `instrumental` flag marks parts that are non-notated, like intros,
   * interludes and outros.
   */
  instrumental: boolean;
  verseNumber: string | null;
  manifestationRef: string;
};

export type ClipInfo = {
  begin: number;
  end: number;
  recordingId: string;
  ending: boolean;
  clip: Element;
  label: string;
  avFileName: string;
  manifestationRefs: Set<string>;
  section?: SectionInfo;
  id: string;
};

export type RecordingInfo = {
  type: string;
  lang: string;
  recording: Element;
  id: string;
};

const meiTemplate =
  /*prettier-ignore*/ ["mei", { meiversion: "4.0.0" }, [
  ["meiHead", [
    ["fileDesc", [
      ["titleStmt", [
        ["title", { type: "main" }],
        ["title", { type: "originalTitle" }],
      ]],
      ["pubStmt", [
        ["publisher", [
          ["corpName", "Kohelet 3 GmbH & Co. KG"],
          ["address", [
            ["street", "Augustenstr. 124"],
            ["postCode", "70197"],
            ["settlement", "Stuttgart"],
            ["country", "Germany"],
          ]],
        ]],
        ["availability", [
          ["accessRestrict", "Text: Texter, Musik: Komponist. © Verlag"],
        ]],
      ]],
      ["seriesStmt", [
        ["title"],
        ["identifier"],
      ]],
      ["notesStmt", [
        ["annot", { type: "bpm" }],
      ]],
    ]],
    ["manifestationList"],
  ]],
  ["music", [
    ["performance", [
      ["recording", { betype: "smil" }],
    ]],
    ["body", [
      ["mdiv", [
        ["score", [
          ["scoreDef", { "meter.unit": "4", "meter.count": "4" }, [
            ["staffGrp"],
          ]],
          ["section"],
        ]],
      ]],
    ]],
  ]],
]] as ElementTemplate;

export default class CanticoDomEditor extends MeiDomEditor {
  constructor(mei?: Document | string | ElementTemplate) {
    super(mei || meiTemplate);

    this.adaptToTemplate(meiTemplate);

    this.autoAddIds = true;
  }

  load(mei: Document | string | ElementTemplate) {
    MeiDomEditor.prototype.load.call(this, mei);
    this.adaptToTemplate(meiTemplate);
  }

  /**
   * Analyses the song and compiles SectionInfos, indexable by different means.
   */
  sectionsInfo = new this.DynamicProperty(
    [
      // TODO: Can we do this without watching all the label attributes in the subtree?
      "/mei/music/body/mdiv/score/(node()|.//@label)",
    ],
    ([[score]]) => {
      const sections = this.findAll("section", score as Element) as Element[];

      const byIndex: SectionInfo[] = [];
      const byRef: { [id: string]: SectionInfo } = {};
      const byLabel: { [label: string]: SectionInfo[] } = {};

      for (const [index, section] of sections.entries()) {
        const id = this.getOrCreateId(section);
        const verseNumbers = this.collectVerseNumbers(section);
        const label = section.getAttribute("label") || "";
        const sectionInfo = { section, id, label, verseNumbers, labelIsUnique: true };

        if (!byLabel[label]) {
          byLabel[label] = [];
        }
        byLabel[label].push(sectionInfo);
        byRef["#" + id] = sectionInfo;
        byIndex[index] = sectionInfo;
      }

      for (const sections of Object.values(byLabel)) {
        if (sections.length > 1) {
          for (const section of sections) {
            section.labelIsUnique = false;
          }
        }
      }

      return { byRef, byIndex, byLabel };
    },
  );

  manifestationsInfo = new this.DynamicProperty(
    [
      // TODO: Why won't this work when only observing node() (without .//)? In
      // other words, why does childList=true not suffice and we also need
      // subtree=true?
      "/mei/meiHead/manifestationList/(.//node()|.//@*)",
    ],
    ([[manifestationList]]) => {
      const manifestations = this.findAll(
        "manifestation",
        manifestationList as Element,
      ) as Element[];
      const bySectionId: { [sectionId: string]: { [verseNumber: string]: Element } } = {};
      const byRef: { [ref: string]: Element } = {};
      for (const manifestation of manifestations) {
        byRef["#" + this.getOrCreateId(manifestation)] = manifestation;
        const sectionId = manifestation.getAttribute("data")?.substring(1);
        if (!sectionId) {
          continue;
        }
        const verseNumber = manifestation.getAttribute("n") || "";
        if (!bySectionId[sectionId]) {
          bySectionId[sectionId] = {};
        }
        bySectionId[sectionId][verseNumber] = manifestation;
      }
      return {
        manifestationList: manifestationList as Element,
        manifestations,
        bySectionId,
        byRef,
      };
    },
  );

  /**
   * Analyses the song and compiles a `SongInfo` object
   */
  songInfo = new this.DynamicProperty([this.sectionsInfo, this.manifestationsInfo], () => {
    const sectionsInfo = this.sectionsInfo.value;
    const manifestationsInfo = this.manifestationsInfo.value;

    const orphanedVerses: { sectionInfo: SectionInfo; verseNumber: string }[] = [];
    for (const sectionInfo of sectionsInfo.byIndex) {
      const manifestationByVerseNumber = manifestationsInfo.bySectionId[sectionInfo.id];
      for (const verseNumber of sectionInfo.verseNumbers) {
        if (!manifestationByVerseNumber || !manifestationByVerseNumber[verseNumber]) {
          orphanedVerses.push({ sectionInfo, verseNumber });
        }
      }
    }

    const danglingManifestations: Element[] = [];
    const form: VerseInfo[] = [];
    for (const manifestation of manifestationsInfo.manifestations) {
      const sectionRef = manifestation.getAttribute("data");
      const verseNumber = manifestation.getAttribute("n") || "";
      const sectionInfo = sectionsInfo.byRef[sectionRef || ""] as SectionInfo | undefined;
      const manifestationLabel = manifestation.getAttribute("label");
      const label = manifestationLabel || sectionInfo?.label || "";

      const errors = [];
      if (!sectionInfo || !sectionInfo.verseNumbers.has(verseNumber)) {
        danglingManifestations.push(manifestation);
        errors.push("@data points to non-existing section " + sectionRef);
      }
      if (sectionRef && manifestationLabel) {
        errors.push("@data and @label on <manifestation>s should be mutually exclusive");
      }

      form.push({
        manifestation,
        sectionInfo,
        verseNumber,
        label,
        instrumental: sectionRef === null,
        manifestationRef: "#" + this.getOrCreateId(manifestation),
      });
    }

    const duplicateSectionLabels = Object.keys(sectionsInfo.byLabel).filter(
      (label) => sectionsInfo.byLabel[label].length > 1,
    );

    return { form, orphanedVerses, danglingManifestations, duplicateSectionLabels };
  });

  recordingsInfo = new this.DynamicProperty(
    ["/mei/music/performance/(.//node()|.//@*)"],
    ([[performance]]) => {
      const recordingsInfo: { [id: string]: RecordingInfo } = {};
      for (const recording of this.findAll("recording", performance as Element) as Element[]) {
        const id = this.getOrCreateId(recording);
        recordingsInfo[id] = {
          type: recording.getAttribute("type") || "[no type]",
          lang: recording.getAttribute("xml:lang") || "",
          recording,
          id,
        };
      }
      return recordingsInfo;
    },
  );

  removeOrphanManifestations(songInfo?: SongInfo) {
    songInfo = songInfo || this.songInfo.value;
    for (const manifestation of songInfo.danglingManifestations) {
      manifestation.parentElement!.removeChild(manifestation);
    }
  }

  addMissingVerses(songInfo?: SongInfo) {
    songInfo = songInfo || this.songInfo.value;
    const manifestationList = this.manifestationsInfo.value.manifestationList;
    for (const verseInfo of songInfo.orphanedVerses) {
      manifestationList.appendChild(
        this.buildNode([
          "manifestation",
          {
            data: "#" + verseInfo.sectionInfo.id,
            n: verseInfo.verseNumber || null,
          },
        ]),
      );
    }
  }

  collectVerseNumbers(section: Element): Set<string> {
    const verseNumberMap = new Set<string>();
    for (const verseElement of section.querySelectorAll("verse")) {
      verseNumberMap.add(verseElement.getAttribute("n") || "");
    }
    if (verseNumberMap.size === 0) {
      verseNumberMap.add("");
    }
    return verseNumberMap;
  }

  /**
   * Creates a label that visualizes what the clip is associated with.
   *
   * The label is only intended to be used for display and should not be used in
   * any further processing. It is not guaranteed to be unique or to have a
   * specific syntax.
   *
   * The label may be multi-line, combining labels for all the manifestations
   * the cliip is associated with. When creating manifestation labels, `@label`
   * attributes on the manifestation element take precedence over section
   * labels. If neither is present, the clips's ID is used as a fallback.
   */
  private createClipLabel(
    clip: Element,
    sectionsByRef: { [id: string]: SectionInfo },
    manifestationsByRef: { [ref: string]: Element },
  ) {
    const labels = new Set();

    for (const ref of (clip.getAttribute("synch") || "").split(/\s+/)) {
      const manifestation = (manifestationsByRef[ref] as Element) || undefined;
      if (manifestation?.hasAttribute("label")) {
        labels.add("m:" + manifestation.getAttribute("label"));
        continue;
      }
      const sectionRef = manifestation?.getAttribute("data");
      const sectionLabel = sectionRef && sectionsByRef[sectionRef]?.label;
      if (sectionLabel) {
        const verseNumber = manifestation!.getAttribute("n");
        labels.add(`s:${sectionLabel}${verseNumber ? " " + verseNumber : ""}`);
      }
    }

    if (labels.size === 0) {
      labels.add("c:" + this.getOrCreateId(clip));
    }

    const ending = clip.getAttribute("type") === "ending" ? "[ending]\n" : "";
    return ending + [...labels].sort().join("\n");
  }

  clips = new this.DynamicProperty(
    ["/mei/music/performance/(.//node()|.//@*)"],
    ([[performance]]) => {
      const recordings = this.findAll("./recording", performance as Element);
      const sectionsByRef = this.sectionsInfo.value.byRef;
      const manifestationsByRef = this.manifestationsInfo.value.byRef;

      const clips: ClipInfo[] = [];
      const byId: { [id: string]: ClipInfo } = {};

      for (const recording of recordings as Element[]) {
        const recordingId = this.getOrCreateId(recording);

        for (const avFile of this.findAll("avFile", recording)) {
          const avFileName = (avFile as Element).getAttribute("target");
          if (!avFileName) {
            throw new Error("Missing @target attribute on <avFile>");
          }

          for (const clip of this.findAll("clip", avFile) as Element[]) {
            const beginString = clip.getAttribute("begin") || "";
            const begin = parseFloat(beginString);
            const endString = clip.getAttribute("end") || "";
            const end = parseFloat(endString);
            if (isNaN(begin) || isNaN(end)) {
              throw new Error(
                `@begin and @end attributes must be numbers. Found @begin="${beginString}" and @end=${endString}`,
              );
            }
            const manifestations = (clip.getAttribute("synch")?.split(/\s+/) || []).map(
              (ref) => manifestationsByRef[ref],
            );

            const sectionRefs = [
              ...new Set(
                manifestations
                  .map((manifestation) => manifestation.getAttribute("data"))
                  .filter((ref) => ref),
              ),
            ] as string[];
            if (sectionRefs.length > 1) {
              throw new Error(
                `<clip> ${this.getOrCreateId(clip)} is associated with multiple <section>s (${[
                  ...sectionRefs,
                ].join(
                  ", ",
                )}) but for alignment reasons may only be associated with at most one <section>`,
              );
            }
            const section = sectionRefs.length > 0 ? sectionsByRef[sectionRefs[0]] : undefined;
            const clipInfo = {
              begin,
              end,
              recordingId,
              ending: clip.getAttribute("type") === "ending",
              clip,
              label: this.createClipLabel(clip, sectionsByRef, manifestationsByRef),
              avFileName,
              manifestationRefs: new Set(clip.getAttribute("synch")?.split(/\s+/) || []),
              id: this.getOrCreateId(clip),
              section,
            };
            clips.push(clipInfo);
            byId[clipInfo.id] = clipInfo;
          }
        }
      }

      // Sort the clips so we get nicely ordered results when filtering
      clips.sort((a, b) => {
        const beginDelta = a.begin - b.begin;
        if (beginDelta !== 0) {
          return beginDelta;
        }
        const endDelta = a.end - b.end;
        if (endDelta !== 0) {
          return endDelta;
        }
        // Just to have reliable search order, especially to make this reliably
        // testable
        return this.getOrCreateId(a.clip) > this.getOrCreateId(b.clip) ? 1 : -1;
      });

      return { clips, byId };
    },
  );

  /**
   * Removes either
   *
   * * only the provided `manifestationRef` from the clip `@synch`
   *   attribute
   * * or (if that would result in an empty `@synch` attribute) the entire
   *   `<clip>`
   * * or (if *that* would leave an empty `<avFile>` element) the entire
   *   `<avFile>`
   */
  removeClip(clip: ClipInfo, manifestationRef: string) {
    clip.manifestationRefs.delete(manifestationRef);
    if (clip.manifestationRefs.size > 0) {
      // This clip also points to other manifestations, so only remove the
      // current manifestation from the @synch attribute
      // TODO: Move this functionality (or the entire setClip() function)
      // to the CanticoDomEditor
      clip.clip.setAttribute("synch", [...clip.manifestationRefs].join(" "));
    } else if (clip.clip.parentElement!.childElementCount > 1) {
      // This clip would be pointing nowhere, so remove it.
      this.remove(clip.clip);
    } else {
      // We'd be left with an empty <avFile> element
      this.remove(clip.clip.parentElement!);
    }
  }

  /**
   * @returns The found clip (if any) and a list of related clips from the same
   * recording with the same audio file and the same "variant" (i.e. default or
   * ending).
   */
  private findClip(
    clipDescription: ClipInfo | { recordingId: string; avFileName: string; ending: boolean },
    manifestationRef: string,
    sectionId?: string,
  ) {
    const relatedClips = this.clips.value.clips.filter(
      (clip) =>
        clip.recordingId === clipDescription.recordingId &&
        clip.ending === clipDescription.ending &&
        clip.section?.id === sectionId,
    );
    const clipsForManifestation = relatedClips.filter((clip) =>
      clip.manifestationRefs.has(manifestationRef),
    );
    if (clipsForManifestation.length > 1) {
      throw new Error(
        `Expected a single clip for manifestation #${manifestationRef} (${
          clipDescription.ending ? "ending" : "default"
        }) in recording #${
          clipDescription.recordingId
        }, but found all of the following:\n${clipsForManifestation
          .map((clip) => "  #" + clip.id)
          .join("\n")}`,
      );
    }
    return {
      clip: clipsForManifestation[0] as ClipInfo | undefined,
      similarClips: relatedClips.filter((clip) => clip.avFileName === clipDescription.avFileName),
    };
  }

  /**
   * @param clipDescription If there is a pre-existing clip for the given
   * recording, manifestation and ending type, this clip must be provided. This
   * clip will be modified to reflect the desired clip properties (`begin`, end
   * `and` `manifestationRef`). If the clip's `manifestationRefs` point to
   * multiple manifestations, then `manifesatationRef` will be removed from
   * `clip` and a new clip will be created and returned.
   *
   * If there is no pre-existing clip, a new clip will be created with the
   * information found in the context object.
   * @returns The new or modified `<clip>` element, or `undefined` if there was
   * nothing to be changed.
   */
  setClip(
    clipDescription: ClipInfo | { recordingId: string; avFileName: string; ending: boolean },
    manifestationRef: string,
    begin: number,
    end: number,
  ) {
    const manifestation = this.manifestationsInfo.value.byRef[manifestationRef];
    const sectionId = manifestation.getAttribute("data")?.substring(1);
    const { clip, similarClips } = this.findClip(clipDescription, manifestationRef, sectionId);

    if (clip?.manifestationRefs.has(manifestationRef) && clip.begin === begin && clip.end === end) {
      // Everything is already as desired, nothing to do!
      return undefined;
    }

    if (clip?.manifestationRefs.size === 1) {
      // This clip does not point anywhere else, so we can modify it
      clip.clip.setAttribute("begin", begin.toString());
      clip.clip.setAttribute("end", end.toString());
      return clip.clip;
    }

    // We have to find or create a new clip for the properties we want
    clip?.manifestationRefs.delete(manifestationRef);
    clip?.clip.setAttribute("synch", [...clip.manifestationRefs].join(" "));

    for (const clip of similarClips.filter(
      (clip) => clip.begin === begin && clip.end === end && clip.ending === clipDescription.ending,
    )) {
      // We found a clip that has all the desired properties. Just add the link
      // to our manifestation and we're done
      clip.manifestationRefs.add(manifestationRef);
      clip.clip.setAttribute("synch", [...clip.manifestationRefs].join(" "));
      return clip.clip;
    }

    // We have to create a new clip, or maybe even a new <avFile> element
    const recording = this.recordingsInfo.value[clipDescription.recordingId]?.recording;
    if (!recording) {
      throw new Error(
        `Can not create clip for recording #${clipDescription.recordingId}. <recording> element not found.`,
      );
    }
    let avFileElement = this.findFirst("avFile[@target=$avFile]", recording, {
      avFile: clipDescription.avFileName,
    });
    if (!avFileElement) {
      avFileElement = this.buildNode(["avFile", { target: clipDescription.avFileName }]);
      recording.appendChild(avFileElement);
    }
    const newClip = this.buildNode([
      "clip",
      {
        begin: begin.toString(),
        end: end.toString(),
        synch: manifestationRef,
        type: clipDescription.ending ? "ending" : "default",
      },
    ]) as Element;
    (avFileElement as Element).appendChild(newClip);
    return newClip;
  }

  addRecording() {
    const performance = this.findFirst("/mei/music/performance");
    if (!performance) {
      throw new Error("Could not find <performance> element");
    }
    const recording = this.buildNode(["recording", { betype: "smil" }]) as Element;
    performance.appendChild(recording);
    return this.getOrCreateId(recording);
  }

  addManifestation(label: string) {
    this.buildChildren(this.manifestationsInfo.value.manifestationList, [
      ["manifestation", { label }],
    ]);
  }
}
