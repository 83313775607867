import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { AvTimer, Edit, Pause, PlayArrow } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import { Aligner } from "../lib/Aligner";
import CanticoDomEditor, { ClipInfo, VerseInfo } from "../lib/CanticoDomEditor";
import { File } from "../lib/GitHub";
import { VerovioAligner } from "../lib/VerovioAligner";
import { WaloschekAligner } from "../lib/WaloscheckAligner";
import ClipEditor from "./ClipEditor";
import { setClipAndAlign, createActiveEventGetter, useDynamicProperty } from "./utils";

/**
 * @returns The time range of the clip, formatted as string like "1:23–3:45"
 */
function formatClipTime(clip?: ClipInfo) {
  if (!clip) {
    return "";
  }
  const times = [];
  for (const time of ["begin", "end"] as ("begin" | "end")[]) {
    const seconds = Math.round(clip[time]);
    const minutes = Math.floor(seconds / 60);
    times.push(`${minutes}:${(seconds % 60).toString().padStart(2, "0")}`);
  }
  return times.join("–");
}

const NO_CLIP = "-";

export default function ClipChooser({
  clip,
  avFiles,
  ending,
  song,
  verseInfo,
  chosenRecordingId,
  audioCache,
  setWait,
  highlightElements,
}: {
  clip?: ClipInfo;
  avFiles: File[];
  ending: boolean;
  song: CanticoDomEditor;
  verseInfo: VerseInfo;
  chosenRecordingId: string;
  audioCache: { [name: string]: HTMLAudioElement };
  setWait: (wait: boolean) => void;
  highlightElements: (refs: string[]) => void;
}) {
  const [clipEditorOpen, setClipEditorOpen] = useState(false);
  const [syncDialogOpen, setSyncDialogOpen] = useState(false);
  const [audio, setAudio] = useState(undefined as HTMLAudioElement | undefined);
  const [avFileName, setAvFileName] = useState(clip?.avFileName || NO_CLIP);
  useEffect(() => setAvFileName(clip?.avFileName || NO_CLIP), [clip]);
  const [playing, setPlaying] = useState(false);

  const getClipRanges = useCallback(
    // Collect one representative for each `begin` to `end` range
    (explicitAvFileName?: string) => {
      const clips: ClipInfo[] = [];
      for (const clip of song.clips.value.clips) {
        if (
          clip.avFileName === (explicitAvFileName || avFileName) &&
          !clips.find((otherClip) => otherClip.begin === clip.begin && otherClip.end === clip.end)
        ) {
          clips.push(clip);
        }
      }
      return clips;
    },
    [avFileName, song],
  );

  const clipRanges = useDynamicProperty(song.clips, getClipRanges);

  const getAudioDataUrl = useCallback(
    (avFileName: string) => {
      const avFile = avFiles.filter((avFile) => avFile.name === avFileName)[0];
      if (!avFile) {
        throw new Error(
          avFileName +
            " is not found in the list of available audio files for this song. Available audios are:\n  " +
            avFiles.map((avFile) => avFile.name).join("\n  "),
        );
      }
      return avFile.mp3DataUrl();
    },
    [avFiles],
  );

  const getAudio = useCallback(
    async (newAvFileName: string): Promise<HTMLAudioElement> => {
      if (newAvFileName === avFileName && audio) {
        // Nothing changed, nothing new to load
        return audio;
      }
      if (audioCache[newAvFileName]) {
        return audioCache[newAvFileName];
      }
      setWait(true);
      const newAudio = new Audio(await getAudioDataUrl(newAvFileName));
      setAudio(newAudio);
      // Properties like "duration" are not readable before data is ready
      return new Promise((resolve) => {
        newAudio.addEventListener("canplay", () => {
          setWait(false);
          audioCache[newAvFileName] = newAudio;
          resolve(newAudio);
        });
      });
    },
    [audio, audioCache, avFileName, getAudioDataUrl, setWait],
  );

  const play = useCallback(
    async (fromStart?: boolean) => {
      if (!clip) {
        return;
      }
      const getActiveEvents = createActiveEventGetter(clip.clip);
      const audio = await getAudio(avFileName);

      let lastUpdateTime = audio.currentTime;

      const timeupdate = () => {
        if (audio.currentTime >= clip.end) {
          audio.pause();
        }
        // Compensate for the time between two timeupdate events. Better
        // overcompensate than undercompensate.
        const refs = getActiveEvents(2 * audio.currentTime - lastUpdateTime);
        highlightElements(refs.map((ref) => ref.replace(/^#(.+)$/, "[data-id=$1]")));
        lastUpdateTime = audio.currentTime;
        setPlaying(!audio.paused);
      };

      // We want the playback to stop at the end. Use a timeout in case timeupdate
      // doesn't fire quick enough to stop playback before the next clip starts.
      const timeoutId = window.setTimeout(
        () => audio.pause(),
        (clip.end - audio.currentTime) * 1000,
      );

      const pause = () => {
        window.clearTimeout(timeoutId);
        setPlaying(false);
        // We have to clean up the event listeners, otherwise event listeners will
        // pile up on every new play() call.
        audio.removeEventListener("timeupdate", timeupdate);
        audio.removeEventListener("pause", pause);
        audio.removeEventListener("ended", ended);
      };

      const ended = () => {
        highlightElements([]);
        pause();
      };

      audio.addEventListener("timeupdate", timeupdate);
      audio.addEventListener("pause", pause);
      audio.addEventListener("ended", ended);
      if (fromStart || audio.currentTime > clip.end || audio.currentTime < clip.begin) {
        audio.currentTime = clip.begin;
      }
      audio.play();
    },
    [avFileName, clip, getAudio, highlightElements],
  );

  const setAvFile = useCallback(
    async (avFileName: string) => {
      if (!chosenRecordingId && !clip) {
        alert("Choose an audio variant before assigning clips to it");
        return;
      }
      setAvFileName(avFileName);
      // clipRanges updates asynchronously after settings avFileName, so fetch
      // it explicitly here
      const clipRanges = getClipRanges(avFileName);

      let clipInfo = { avFileName, ending, recordingId: chosenRecordingId };
      if (clip && clip.avFileName !== avFileName) {
        song.removeClip(clip, verseInfo.manifestationRef);
      } else if (clip) {
        clipInfo = clip;
      }
      if (avFileName === NO_CLIP) {
        setAudio(undefined);
        return;
      }

      setWait(true);
      const audio = await getAudio(avFileName);
      // Check if this audio file is already in use and, if so, if it is split
      // into shorter clips
      if (
        clipRanges.length === 0 ||
        (clipRanges[0].begin === 0 && Math.abs(clipRanges[0].end - audio.duration) < 0.001)
      ) {
        // Just assign entire audio – the recording has so far been treated as a
        // single clip
        try {
          await setClipAndAlign(song, clipInfo, verseInfo.manifestationRef, 0, audio.duration);
        } catch (e) {
          alert(e);
        }
      } else {
        // Clip has to be chosen or created in clip editor.  If the clip editor
        // dialog is aborted, nothing will be changed.
        setAudio(audio);
        // setClipRanges(clipRanges);
        setClipEditorOpen(true);
      }
      setWait(false);
    },
    [chosenRecordingId, clip, ending, getAudio, getClipRanges, setWait, song, verseInfo],
  );

  const align = useCallback(
    (aligner: Aligner | undefined) => {
      setSyncDialogOpen(false);
      if (!aligner) {
        alert("No active clip to align");
        return;
      }
      setWait(true);
      aligner
        .align()
        .catch((e) => alert("Fetching data from alignment server failed:\n\n" + e))
        .finally(() => {
          // Use setTimeout() to make sure the alignment dialog is gone before
          // displaying the confirm() dialog
          setTimeout(() => {
            setWait(false);
            window.confirm("Aligment updated. Play the clip for checking the new alignment?") &&
              play(true);
          });
        });
    },
    [play, setWait],
  );

  const quickAlign = useCallback(() => {
    align(clip && new VerovioAligner(song, clip));
  }, [align, clip, song]);

  const advancedAlign = useCallback(async () => {
    align(clip && new WaloschekAligner(song, clip, await getAudioDataUrl(avFileName)));
  }, [align, avFileName, clip, getAudioDataUrl, song]);

  return (
    <div className={`ClipChooser${clip ? "" : " no-clip"}`}>
      <Select
        value={clip?.avFileName || NO_CLIP}
        onChange={(e) => setAvFile(e.target.value as string)}
      >
        <MenuItem key={NO_CLIP} value={NO_CLIP}>
          {ending ? "Ending: Same audio clip" : "No audio clip"}
        </MenuItem>
        {avFiles.map(({ name }) => (
          <MenuItem key={name} value={name}>
            {(ending ? "Ending: " : "") + name}
          </MenuItem>
        ))}
      </Select>
      <span className="clip-time">{formatClipTime(clip)}</span>
      {clip && (
        <div className="clip-edit-buttons">
          {audio && playing ? (
            <IconButton title="pause clip" onClick={() => audio.pause()}>
              <Pause />
            </IconButton>
          ) : (
            <IconButton title="play clip" onClick={() => play()}>
              <PlayArrow />
            </IconButton>
          )}
          <IconButton
            title="choose or edit audio clip for verse"
            onClick={async () => {
              setAudio(await getAudio(avFileName));
              setClipEditorOpen(true);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton title="re-sync audio for verse" onClick={() => setSyncDialogOpen(true)}>
            <AvTimer />
          </IconButton>
          <Dialog open={syncDialogOpen} onClose={() => setSyncDialogOpen(false)}>
            <DialogTitle>Re-align audio with music</DialogTitle>
            <dl>
              <dt>Quick aligmnent</dt>
              <dd>
                assumes constant tempo over the duration of the audio clip. This alignment is used
                automatically when assigning clips. Good for accurately cut clips with stable tempo
                and without silence at beginnnig or end.
              </dd>
              <dt>Advanced alignment</dt>
              <dd>
                uses server side audio analysis, which may take a few seconds. Good for audio clips
                with varying tempo, fermatas or silence at the beginning or end. Results may be of
                varying quality.
              </dd>
            </dl>
            <DialogActions>
              <Button onClick={quickAlign}>Quick alignment</Button>
              <Button autoFocus onClick={advancedAlign}>
                Advanced alignment
              </Button>
              <Button onClick={() => setSyncDialogOpen(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <Modal open={clipEditorOpen}>
        {audio && avFileName ? (
          <ClipEditor
            song={song}
            clip={clip || { recordingId: chosenRecordingId, avFileName, ending }}
            audio={audio}
            clipRanges={clipRanges}
            setOpen={setClipEditorOpen}
            verseInfo={verseInfo}
            setWait={setWait}
          />
        ) : (
          <div>
            Clip editor not ready yet{!audio && ". No audio"}
            {avFileName && " for " + avFileName}
          </div>
        )}
      </Modal>
    </div>
  );
}
