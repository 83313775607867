import isNode from "detect-node";

const globals = (() => {
  const win = isNode ? (new (require("jsdom").JSDOM)()).window : window;
  return {
    parser: new win.DOMParser() as DOMParser,
    serializer: new win.XMLSerializer() as XMLSerializer,
    MutationObserver: win.MutationObserver as { new(callback: MutationCallback): MutationObserver },
  };
})();

export default globals;
