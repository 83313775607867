import { Box, Button, IconButton, MenuItem, Modal, Select } from "@material-ui/core";
import FileBrowser, { Icons, FileBrowserFile } from "react-keyed-file-browser";
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";
import "font-awesome/css/font-awesome.min.css";
import { useState } from "react";
import CanticoDomEditor from "../lib/CanticoDomEditor";
import { File, Repo } from "../lib/GitHub";
import { Refresh } from "@material-ui/icons";
import { repos } from "../config/config.json";

export default function IoButtons({
  accessToken,
  setAccessToken,
  song,
  setSong,
  setAvFiles,
  setWait,
}: {
  accessToken: string;
  setAccessToken: (accessToken: string) => void;
  song?: CanticoDomEditor;
  setSong: (song: CanticoDomEditor) => void;
  setAvFiles: (avFiles: File[]) => void;
  setWait: (wait: boolean) => void;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [browserFiles, setBrowserFiles] = useState(undefined as FileBrowserFile[] | undefined);
  const [onOpenFile, setOnOpenFile] = useState(() => async (file: FileBrowserFile) => {});
  const [repo, setRepo] = useState((localStorage.getItem("repo") || repos[0]).split("/"));
  const [meiFile, setMeiFile] = useState(undefined as File | undefined);

  async function reloadFileTree() {
    setWait(true);
    // TODO: Ideally leave the reloading to the Repo object itself
    const repoObject = new Repo(accessToken, repo[0], repo[1]);
    const browsableFiles = [];
    const meiFilesByPath = await repoObject.getMeisByPath();
    for (const file of Object.values(meiFilesByPath)) {
      browsableFiles.push({ key: file.path, modified: 0, size: file.size });
    }
    setBrowserFiles(browsableFiles);

    setOnOpenFile(() => async (file: FileBrowserFile) => {
      const path = file.key;
      let song;
      try {
        song = new CanticoDomEditor(await meiFilesByPath[path].text());
      } catch (e) {
        alert(`${path} could not be opened: ${e}`);
        return;
      } finally {
        setWait(false);
      }
      setSong(song);
      const meiFile = meiFilesByPath[path];
      if (!meiFile) {
        alert("Error loading " + path);
        return;
      }
      setMeiFile(meiFile);
      setAvFiles((await meiFile.parentFolder!.audioFiles()) as File[]);
      setDialogOpen(false);
    });
    setWait(false);
  }

  async function openFileBrowser() {
    if (!browserFiles) {
      await reloadFileTree();
    }
    setDialogOpen(true);
  }

  async function saveFile() {
    if (!song) {
      return;
    }
    const message = prompt(
      "Describe your changes in a short commit message",
      "Changed " + meiFile?.path,
    );
    if (!message) {
      return;
    }
    setWait(true);
    await meiFile!.save(song.serialize(), message);
    setWait(false);
  }

  function chooseRepo(repoChoice: string) {
    if (repoChoice !== repo.join("/")) {
      const newRepo = repoChoice.split("/");
      setRepo(newRepo);
      setBrowserFiles(undefined);
      localStorage.setItem("repo", repoChoice);
    }
  }

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    height: "100%",
    overflow: "auto",
  };

  return accessToken ? (
    <div className="IoButtons leftsideBox">
      <Select
        value={repo.join("/")}
        onChange={(e) => {
          chooseRepo(e.target.value as string);
        }}
      >
        {repos.map((repo) => (
          <MenuItem key={repo} value={repo}>
            {repo}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={reloadFileTree} title="refresh file list from GitHub server">
        <Refresh />
      </IconButton>
      <Button onClick={openFileBrowser}>Open</Button>
      {song && meiFile && (
        <>
          <Button onClick={saveFile}>Save</Button>
          <div className="filename">{meiFile?.path || ""}</div>
        </>
      )}
      <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <Box sx={modalStyle}>
          <FileBrowser
            files={browserFiles || []}
            icons={Icons.FontAwesome(4)}
            headerRenderer={null}
            onSelectFile={onOpenFile}
          />
        </Box>
      </Modal>
    </div>
  ) : (
    <div>Authenticating at GitHub...</div>
  );
}
