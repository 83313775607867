import { Aligner } from "./Aligner";
import newVerovio from "./Verovio";

export class VerovioAligner extends Aligner {
  /**
   * If the section has no notes with duration until the section end, returns a
   * clone of the section with one rest at the end converted to a note. This is
   * workaround for finding the full duration (including final rests) of the
   * section when using Verovio's `renderToTimemap()`.
   */
  preprocessSection(section: Element) {
    // If the section does not end with a note or chord, we'll estimate a wrong
    // duration of the entire section.
    const lastMeasure = [...section.querySelectorAll("measure")].pop();
    if (!lastMeasure) {
      return section;
    }
    const lastLayers = this.song.findAll(".//layer", lastMeasure);
    const lastEventXpath = `(
        note|rest|mRest|beatRpt|halfmRpt|mRpt|mRpt2|mSpace|multiRest|multiRpt|space
      )[last()]`;
    const layerEndingWithNote = lastLayers.find((layer) => {
      const lastEvent = this.song.findFirst(lastEventXpath, layer);
      return lastEvent?.nodeName === "note";
    });

    if (layerEndingWithNote) {
      return section;
    }

    // There is no layer ending with a note. Replace the last note of the first
    // layer with a dummy note.
    const trailingRest = lastLayers.map((layer) =>
      this.song.findFirst(lastEventXpath + "[self::rest|self::space][@dur]", layer),
    )[0] as Element | undefined;
    if (!trailingRest) {
      console.error(
        `Alignment for <section> ${section.id} will be inaccurate because last event is neither a note nor a rest.`,
      );
      return section;
    }

    const trailingRestId = this.song.getOrCreateId(trailingRest as Element);
    const clonedSection = section.cloneNode(true) as Element;
    const clonedRest = this.song.findFirst(".//*[@xml:id=$trailingRestId]", clonedSection, {
      trailingRestId,
    }) as Element;
    const replacementNote = this.song.buildNode([
      "note",
      {
        dur: trailingRest.getAttribute("dur"),
        dots: trailingRest.getAttribute("dots"),
        "xml:id": trailingRest.getAttribute("xml:id"),
      },
    ]);
    clonedRest.parentElement!.replaceChild(replacementNote, clonedRest);

    return clonedSection;
  }

  /**
   * Uses Verovio's `renderToTimemap()` method to generate `<when>` elements for
   * the given clip.
   */
  async createWhenElements(alignmentScore: string) {
    const verovio = await newVerovio();
    if (!verovio.loadData(alignmentScore)) {
      throw new Error("Verovio could not load XML string for alignment");
    }
    const timemap = verovio.renderToTimemap();
    if (timemap.length === 0) {
      return;
    }
    const timemapDuration = timemap[timemap.length - 1].tstamp;
    const timeFactor = (this.clip.end - this.clip.begin) / timemapDuration;
    const offset = this.clip.begin;
    for (const event of timemap) {
      const absolute = (event.tstamp * timeFactor + offset).toString();
      if (event.off) {
        this.song.buildChildren(this.clip.clip, [
          ["when", { type: "off", data: "#" + event.off.join(" #"), absolute }],
        ]);
      }
      if (event.on) {
        this.song.buildChildren(this.clip.clip, [
          ["when", { type: "on", data: "#" + event.on.join(" #"), absolute }],
        ]);
      }
    }
  }
}
