import CanticoDomEditor, { ClipInfo } from "./CanticoDomEditor";

export abstract class Aligner {
  song: CanticoDomEditor;
  clip: ClipInfo;

  constructor(song: CanticoDomEditor, clip: ClipInfo) {
    this.song = song;
    this.clip = clip;
  }

  async createWhenElements(alignmentScore: string): Promise<void> {}
  preprocessSection(section: Element) {
    return section;
  }

  /**
   * Creates new `<when>` elements in the provided clip. Clears all pre-existing
   * `<when>`s.
   */
  async align() {
    const section = this.clip.section;
    if (!section) {
      // If there's no associated <section>, there's nothing to align with
      return;
    }

    const scoreDef = this.song.findFirst("preceding-sibling::scoreDef[1]", section.section);
    if (!scoreDef) {
      throw new Error("Could not find <scoreDef> for section #" + section.id);
    }
    const sectionElement = this.preprocessSection(section.section);

    const alignmentScore = `
      <mei xmlns="http://www.music-encoding.org/ns/mei" meiversion="4.0.0">
        <meiHead/>
        <music>
          <body>
            <mdiv>
              <score>
                ${this.song.serialize(scoreDef as Element)}
                ${this.song.serialize(sectionElement)}
              </score>
            </mdiv>
          </body>
        </music>
      </mei>
    `;

    // Clear all content before we add new <when> elements
    this.clip.clip.textContent = "";
    return this.createWhenElements(alignmentScore);
  }
}
