import { MeiDomEditor } from "./MeiDomEditor";
import globals from "./globals";
// For some reason, this does not work:
//   import { parser, serializer, MutationObserver } from "./globals"
const { MutationObserver } = globals;

const doesNotConformMessage = " does not conform to the requirements documented for the MeiDomEditor.DynamicProperty constructor: ";

export type DynamicProperty<T> = {
  value: T;
  uncachedValue: T;
  observe: (callback: MutationCallback) => void;
  stopObserving: (callback: MutationCallback) => void;
};

export type DynamicPropertyClass = new <T>(
  dependencies: DependencyList,
  getter: (resolvedDependencies: ResolvedDependencies) => T,
) => DynamicProperty<T>;

type DependencyList = (string | DynamicProperty<any>)[];
type ResolvedDependencies = (Node | DynamicProperty<any>)[][];

export function DynamicPropertyClassFactory(context: MeiDomEditor): DynamicPropertyClass {
  /**
   * Describes a dynamic property that needs to be re-calculated when the state
   * of the DOM changes.
   */
  return class DynamicValue<T> {
    private callbacks = new Set<WeakRef<MutationCallback>>();

    private context = context;
    private cache?: T;
    private cacheIsValid = false;
    private getter: (dependencies: ResolvedDependencies) => T;
    private dependencies = [] as ResolvedDependencies;


    /**
     * @param dependencies A list of XPath expressions and other
     * DynamicProperties that this DynamicValue depends on.
     *
     * Every XPath expression must consist of a "target node" selector followed
     * by a parenthesized "observed descendants" step.
     *
     * A MutationObserver will be registered on all nodes that match a target
     * node selector. Registering too many MutationObservers will cause
     * performance issues. For this reason, the target node XPath should only
     * match a small number of nodes and care should be taken that an observer
     * will not registered on both a node and some of its descendants because
     * this will lead to events being fired multiple times for the same change
     * event.
     *
     * The parenthesized observed descendants step may contain one or more of the
     * following expressions, separated by `|`:
     *
     * * `node()`
     * * `.//node()`
     * * `@*` or `@myAttribute` (replace `myAttribute` with any attribute name).
     *   Note that MutationObservers are specified to not match namespaced
     *   attributes. Alas, namespaced attributes have to be watched with `@*`.
     * * `.//@*` or `.//@myAttribute` (see above)
     *
     * Note that only these very specific steps are supported. Be specific and
     * try to avoid `.//` if possible.
     *
     * Examples:
     *
     * * `/mei/meiHead/(@resp)`: Observe `<meiHead>` for changes of any attributes.
     *   Note that parentheses are required – `/mei/meiHead/@resp` would throw an
     *   error.
     * * `/(.//node()|.//@*)` Watch everything. Try to be more specific instead
     *   of watching everything, if possible!
     *
     * Bad Example:
     *
     * * `//*(node()|@*)` This will register far too many MutationObservers (one
     *   for each element in the document). This is a very bad idea. See
     *   preceding example for a slightly better approach.
     *
     * Caveats:
     *
     * * Only target nodes found at the time the DynamicProperty was registered
     *   are watched.  The DOM is not watched for new nodes that would match any
     *   target node selector.
     * * Removing a watched target node from the DOM or moving it elsewhere in
     *   the DOM might lead to unexpected behavior.
     */
    constructor(dependencies: DependencyList, getter: (resolvedDependencies: ResolvedDependencies) => T) {
      // We want any use of `this` in the getter to refer to the MeiDomEditor
      this.getter = getter.bind(this.context);

      const observer = new MutationObserver(this.onDependencyChange);

      for (let i = 0; i < dependencies.length; i++) {
        const dependency = dependencies[i];
        if (typeof dependency === "object") {
          dependency.observe(this.onDependencyChange);
          this.dependencies[i] = [dependency];
          continue;
        }

        const xpath = dependency;

        const observedDescendantsByPrefix = {
          "": [] as string[],
          ".//": [] as string[]
        };
        const [, target, observedDescedants] = xpath.match(/(.+)\/\s*\((.+)\)/) || [];
        if (!target) {
          throw new Error("XPath" + doesNotConformMessage + xpath);
        }

        this.dependencies[i] = this.context.findAll(target);

        for (const observedDescendant of observedDescedants.trim().split(/\s*\|\s*/)) {
          // isolate subtree prefix ".//"
          const [, subtree, expression] = observedDescendant.match(/^(.\s*\/\/)?(.+)$/) || [];
          const prefix = subtree ? ".//" : "";
          observedDescendantsByPrefix[prefix].push(expression.trim());
        }

        for (const [prefix, observedDescendants] of Object.entries(observedDescendantsByPrefix)) {
          if (observedDescendants.length === 0) {
            continue;
          }
          const options = this.compileObserverOptions(observedDescendants, prefix !== "");
          for (const targetNode of this.dependencies[i]) {
            observer.observe(targetNode as Node, options);
          }
        }
      }
    }


    private compileObserverOptions(
      observedDescendants: string[], subtree: boolean
    ): MutationObserverInit {
      const options: MutationObserverInit = { subtree, attributes: false, attributeFilter: [] };

      for (const observedDescendant of observedDescendants) {
        const [, prefix, tail] = observedDescendant.match(/^(@|node)(.*)$/) || [];
        switch (prefix) {
          case "@":
            const attributeName = tail.trim();
            // Observe attribute changes
            options.attributes = true;
            if (attributeName === "*") {
              // All attributes are watched if attributeFilter is undefined
              options.attributeFilter = undefined;
            } else {
              // TODO: Assert we have a valid XML name
              if (attributeName.match(/:/)) {
                throw new Error(
                  `MutationObservers can not filter namespaced attributes. Use "@*" instead of "${attributeName}".`,
                );
              }
              options.attributeFilter?.push(attributeName);
            }
            break;
          case "node":
            // Observe descendant element changes
            if (tail.replace(/\s+/g, "") !== "()") {
              throw new Error("XPath" + doesNotConformMessage + observedDescendant + ": expected `()` after `node`");
            }
            options.childList = true;
            break;
          default:
            throw new Error("XPath for observed descendants must be `node()` or a simple attribute selector. Found " + observedDescendant);
        }
      }

      if (!options.attributes) {
        options.attributeFilter = undefined;
      }

      return options;
    }

    private onDependencyChange = (() => {
      // This function is used as a callback but needs to stay bound to `this`.
      // For some reason, doing:
      //   this.onDependencyChange = this.onDependencyChange.bind(this);
      // in the constructor does not have that effect. So circumvent this by
      // defining the callback in a closure that remembers `self`.
      const self = this;

      return function onDependencyChange(record: MutationRecord[], observer: MutationObserver) {
        self.cacheIsValid = false;
        for (const callbackRef of self.callbacks) {
          const callback = self.derefCallback(callbackRef);
          if (callback) {
            setTimeout(callback, 0, record, observer);
          }
        }
      }
    })();

    /**
     * Dereferences `callbackRef` and cleans up the callbacks list if the
     * WeakRef was garbage collected.
     */
    private derefCallback(callbackRef: WeakRef<MutationCallback>): MutationCallback | undefined {
      const callback = callbackRef.deref();
      if (!callback) {
        this.callbacks.delete(callbackRef);
      }
      return callback;
    }

    get value(): T {
      if (!this.cacheIsValid) {
        this.cache = this.getter(this.dependencies);
        this.cacheIsValid = true;
      }
      return this.cache!;
    }

    /**
     * When it is known that an action invalidated the cache, we can access the
     * value directly without the need for asynchronous code.
     *
     * Use sparingly as the cache will be updated multiple times this way (it's
     * also updated when the MutationObserver eventually fires).
     */
    get uncachedValue(): T {
      return (this.cache = this.getter(this.dependencies));
    }

    observe(callback: MutationCallback) {
      this.callbacks.add(new WeakRef(callback));
    }

    /**
     * Stop the callback from being called on observed DOM changes. Calling
     * `stopObserving()` is however note required for cleanup as callbacks going
     * out of scope are allowed to be garbage collected as they are only weakly
     * referenced.
     */
    stopObserving(callback: MutationCallback) {
      // Finding the entry to remove would have lower computational complexity
      // with something like a `WeakMap<(...)=>any,WeakRef<()=>any>>`, but for
      // now, simply looping over everything is the simpler solution.
      for (const callbackRef of this.callbacks) {
        if (this.derefCallback(callbackRef) === callback) {
          this.callbacks.delete(callbackRef);
          // Defensive measure: Don't return immediately, in case the callback
          // was (accidentally?) registered multiple times
        }
      }
    }
  }
}
