import { useCallback, useEffect, useState } from "react";
import CanticoDomEditor from "../lib/CanticoDomEditor";
import { normalizeXml } from "./utils";
import { Schema } from "node-schematron";

import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldgutter";
import "codemirror/mode/xml/xml";
import "codemirror/addon/fold/xml-fold";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/hint/xml-hint";
import "codemirror/addon/search/searchcursor";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/selection/active-line";
import { validateXML } from "../submodule-builds/xmllint-wasm";
import NamespaceResolver from "../lib/NamespaceResolver";

const cooldownMillis = 1000;

function findClosestId(editor: CodeMirror.Editor): string {
  let rowNumber = editor.getCursor().line;
  while (rowNumber >= 0) {
    const [, id] = editor.getLine(rowNumber).match(/xml:id=["']([^"']+)["']/) || [];
    if (id) {
      return id;
    }
    // Searching backwards makes more sense than searching forwards because we
    // might be in an end tag or a text node line and searching backwards will
    // get us closer to the start tag. This strategy will not always give us the
    // correct ID (especially not for elements without ID, or when there are
    // multiple elements on a single line, or when we run into the ID of a
    // descendant element), but it's good enough.
    rowNumber = rowNumber - 1;
  }
  return "";
}

// Load the schema outside the component because with useEffect(), I did not
// find a way to prevent it being loaded twice.
let schema = "";
let schematron: Schema;
const fetchErrorAlert = (e: any) => alert("Failed to fetch schema:\n\n" + e);
schema ||
  fetch("schema.rng")
    .then((r) =>
      r
        .text()
        .then((t) => {
          schema = t;
          try {
            const rng = new DOMParser().parseFromString(t, "application/xml");
            const schematronRoot = new DOMParser().parseFromString(
              "<schema xmlns='http://purl.oclc.org/dsdl/schematron'/>",
              "application/xml",
            ).firstElementChild!;
            for (const rule of rng.querySelectorAll("*|ns, *|pattern")) {
              schematronRoot.appendChild(rule.cloneNode(true));
            }
            schematron = Schema.fromDom(schematronRoot.ownerDocument);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(fetchErrorAlert),
    )
    .catch(fetchErrorAlert);

function mapIdsToLines(xml: string) {
  const lines = xml.split("\n");
  const map: { [id: string]: number } = {};
  for (const [lineIndex, line] of lines.entries()) {
    for (const [, id] of line.matchAll(/xml:id=["']([^"']+)["']/g)) {
      map[id] = lineIndex;
    }
  }
  return map;
}

function closestId(node: Node | null): string | undefined {
  if (!node) {
    return undefined;
  }
  if (node.nodeType === node.ELEMENT_NODE) {
    const id = (node as Element).getAttributeNS(NamespaceResolver.XML_NS, "id");
    if (id) {
      return id;
    }
    return closestId(node.parentNode);
  }
}

CodeMirror.registerHelper("lint", "xml", async (xml: string) => {
  if (!schema) {
    return xml ? [{ from: CodeMirror.Pos(0), message: "Schema not loaded" }] : [];
  }
  let results = [];

  results.push(
    ...(await validateXML({ xml, schema, extension: "relaxng" })).errors
      .filter((e) => e.loc)
      .map((e) => ({
        from: CodeMirror.Pos(e.loc!.lineNumber - 1, 0),
        to: CodeMirror.Pos(e.loc!.lineNumber - 1, 999),
        message: e.message,
      })),
  );

  if (!schematron) {
    results.push({ from: CodeMirror.Pos(0), message: "Schematron could not be loaded" });
  } else {
    const schematronResults = schematron.validateString(xml);
    if (schematronResults.length > 0) {
      const lineNumberById = mapIdsToLines(xml);
      for (const error of schematronResults) {
        const line = lineNumberById[closestId(error.context) || ""] || 0;
        results.push({
          from: CodeMirror.Pos(line, 0),
          to: CodeMirror.Pos(line, 999),
          message: error.message,
        });
      }
    }
  }

  return results;
});

export default function XmlEditor({
  song,
  setSong,
  focusedMeiId,
  setFocusedMeiId,
}: {
  song?: CanticoDomEditor;
  setSong: (song: CanticoDomEditor) => void;
  focusedMeiId: string;
  setFocusedMeiId: (id: string) => void;
}) {
  const [editor, setEditor] = useState(undefined as CodeMirror.Editor | undefined);
  // TODO: This is a dirty hack. Put the cooldownTimeout value into an array so
  // re-renders are prevented when the cooldownTimeout is changed. What's the
  // proper way of preventing onSongChange() being triggered when cooldownTimout
  // changes?
  const [cooldownBeforeEditorUpdate] = useState([] as NodeJS.Timeout[]);
  const [cooldownBeforeSongUpdate] = useState([] as NodeJS.Timeout[]);

  const onSongChange = useCallback(async () => {
    // If the editor has focus (the user may be typing), we don't want to re-set
    // the content.
    if (!editor || editor.hasFocus()) {
      return;
    }
    const [currentTimeout] = cooldownBeforeEditorUpdate;
    currentTimeout && clearTimeout(currentTimeout);
    if (song) {
      cooldownBeforeEditorUpdate[0] = setTimeout(() => {
        normalizeXml(song.serialize()).then((xml) => editor.setValue(xml));
      }, cooldownMillis);
    }
  }, [cooldownBeforeEditorUpdate, editor, song]);

  // Initialize CodeMirror
  useEffect(() => {
    const div = document.getElementById("XmlEditor") as Element;
    div.innerHTML = "";
    const editor = CodeMirror(div, {
      mode: "xml",
      lineNumbers: true,
      foldGutter: true,
      gutters: ["CodeMirror-foldgutter", "CodeMirror-lint-markers"],
      lint: true,
      styleActiveLine: true,
    });
    setEditor(editor);

    editor.on("change", (editor, changeObj) => {
      if (changeObj.origin === "setValue") {
        return;
      }
      const [currentTimeout] = cooldownBeforeSongUpdate;
      currentTimeout && clearTimeout(currentTimeout);
      cooldownBeforeSongUpdate[0] = setTimeout(() => {
        let song;
        const newXmlString = editor.getValue();
        try {
          song = new CanticoDomEditor(newXmlString);
        } catch (e) {}
        song && setSong(song);
      }, cooldownMillis);
    });

    editor.on("cursorActivity", () => {
      const id = editor && findClosestId(editor);
      id && setFocusedMeiId(id);
    });
  }, [cooldownBeforeSongUpdate, setFocusedMeiId, setSong]);

  useEffect(() => {
    onSongChange();

    if (!song) {
      return;
    }

    const observers = song.observe([song.dom], onSongChange, {
      subtree: true,
      attributes: true,
      characterData: true,
      childList: true,
    });

    return () => {
      for (const observer of observers) {
        observer.disconnect();
      }
    };
  }, [song, onSongChange]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    const search = editor.getSearchCursor(`xml:id="${focusedMeiId}"`);
    if (search.findNext()) {
      // first set cursor to start of line and few lines further down. This
      // makes sure we are as far as possible at the beginning of the line and
      // also see a bit more of the element if it encompasses multiple lines.
      editor.setCursor(search.to().line + 8, 0);
      // Now scroll to the ID. At least that makes sure we're inside the tag.
      editor.setCursor(search.to());
      editor.focus();
    }
  }, [editor, focusedMeiId]);

  return <div id="XmlEditor" />;
}
