import Section from './Section';
import { useCallback, useState } from "react";
import CanticoDomEditor from "../lib/CanticoDomEditor";
import { File } from "../lib/GitHub";
import { Button, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useDynamicProperty } from "./utils";

export default function SectionEditor({
  song,
  chosenRecordingId,
  avFiles,
  setWait,
  highlightElements,
}: {
  song: CanticoDomEditor;
  chosenRecordingId: string;
  avFiles: File[];
  setWait: (wait: boolean) => void;
  highlightElements: (refs: string[]) => void;
}) {
  const songInfo = useDynamicProperty(
    song.songInfo,
    useCallback(() => song.songInfo.value, [song]),
  );

  const [audioCache] = useState({} as { [name: string]: HTMLAudioElement });

  return (
    <div className="SectionEditor leftsideBox">
      <div className="top-right-buttons">
        <IconButton
          title="Add instrumental section (intro, interlude, outro)"
          onClick={() => {
            const label = window.prompt("Enter label for new non-notated section");
            label && song.addManifestation(label);
          }}
        >
          <Add />
        </IconButton>
      </div>
      {songInfo.orphanedVerses.length > 0 && (
        <div className="warning">
          The following verses are present in the encoding, but are missing in the section list:
          <ul>
            {songInfo.orphanedVerses.map((verse) => (
              <li key={`${verse.verseNumber}#${verse.sectionInfo.id}`}>
                <span className="verseLabel">{verse.sectionInfo.label} </span>
                <span className="verseNumber">
                  {verse.verseNumber ? verse.verseNumber : "[no verse number]"}
                </span>
              </li>
            ))}
          </ul>
          <Button onClick={() => song.addMissingVerses()}>Add missing verses</Button>
        </div>
      )}
      <div className="sections">
        {songInfo.form.map((verseInfo) => (
          <Section
            verseInfo={verseInfo}
            key={song.getOrCreateId(verseInfo.manifestation)}
            song={song}
            chosenRecordingId={chosenRecordingId}
            avFiles={avFiles}
            setWait={setWait}
            highlightElements={highlightElements}
            audioCache={audioCache}
          />
        ))}
      </div>
    </div>
  );
}
