import { Octokit } from "@octokit/rest";
import { oauthAuthorizationUrl } from "@octokit/oauth-authorization-url";
import config from "../config/config.json";

export const ACCESS_TOKEN = "accessToken";
const AUTH_STATE = "githubAuthState";

// TODO: Use an iframe for authentication. Redirections are obtrusive and make
// it harder to track repeated authentication failures.

/**
 * We interrupt with an alert to inform the user and prevent the browser from
 * being caught in an infinite loop of reloading the page when authentication
 * failed.
 */
function authRetryAlert(message: string) {
  alert(message + "\n\nRetry?");
}

async function accessTokenVerificationError(accessToken: string): Promise<string | undefined> {
  let status;
  try {
    status = (await new Octokit({ auth: accessToken }).request("GET /user")).status;
  } catch (e) {
    status = -400;
  }
  if (status === 200) {
    return;
  }
  localStorage.removeItem("accessToken");
  return `GitHub access token verification failed with code ${status}.`;
}

export async function authenticate(clientId: string): Promise<string> {
  const params = new URL(window.location.href).searchParams;
  const code = params.get("code") || "";
  const state = params.get("state") || "";

  let accessToken = localStorage.getItem(ACCESS_TOKEN);

  if (accessToken && !(await accessTokenVerificationError(accessToken))) {
    return accessToken;
  }

  // We don't have an access token, or it is expired or otherwise invalid

  if (code) {
    // The user just logged in at the GitHub login form and got redirected here
    // with the code parameter set on the URL.
    accessToken = await tradeCodeForAccessToken(code, state);
    const l = window.location;
    window.history.replaceState({}, document.title, l.origin + l.pathname + l.hash);
    if (accessToken) {
      return accessToken;
    }
  }

  // We neither have a valid access token nor a code that we can trade in for
  // one. Redirect to GitHub login form to get an access code.

  // The `state` parameter is supposed to have a random string
  const githubAuthState = Math.random().toString(36).substring(2);
  localStorage.setItem(AUTH_STATE, githubAuthState);
  window.location.href = oauthAuthorizationUrl({
    clientId,
    scopes: ["repo"],
    redirectUrl: window.location.origin,
    state: githubAuthState,
  }).url;

  return "";
}

async function tradeCodeForAccessToken(code: string, receivedAuthState: string): Promise<string> {
  const expectedAuthState = localStorage.getItem(AUTH_STATE);
  localStorage.removeItem(AUTH_STATE);
  if (receivedAuthState !== expectedAuthState) {
    alert(`GitHub authentication failed: state parameters do not match.
        Expected: ${localStorage.getItem(AUTH_STATE) || ""}
        Received: ${receivedAuthState}`);
    return "";
  }
  const host = window.location.hostname;

  const url = `https://${config.githubProxyHost}/cantico-auth.php?${new URLSearchParams({
    code,
    host,
  })}`;
  let result;
  try {
    result = await (await fetch(url)).text();
  } catch (e) {
    authRetryAlert("GitHub authentication failed: " + e);
    return "";
  }

  const params = new URLSearchParams(result);
  const accessToken = params.get("access_token") || "";
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  } else {
    authRetryAlert("GitHub authentication failed: " + params.get("error_description"));
    window.location.href = window.location.origin;
  }

  return accessToken;
}
