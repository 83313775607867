import { Button, TextField } from "@material-ui/core";
import CanticoDomEditor, { ClipInfo, VerseInfo } from "../lib/CanticoDomEditor";
import { File } from "../lib/GitHub";
import { IconButton } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Delete, FileCopy } from "@material-ui/icons";
import ClipChooser from "./ClipChooser";
import { useCallback } from "react";
import { useDynamicProperty } from "./utils";

function MoveManifestation(manifestation: Element, up: boolean) {
  const sibling = up ? manifestation.previousElementSibling : manifestation.nextElementSibling;
  if (!sibling) {
    return;
  }
  sibling.insertAdjacentElement(up ? "beforebegin" : "afterend", manifestation);
}

export default function Section({
  song,
  verseInfo,
  avFiles,
  chosenRecordingId,
  audioCache,
  setWait,
  highlightElements,
}: {
  song: CanticoDomEditor;
  verseInfo: VerseInfo;
  avFiles: File[];
  chosenRecordingId: string;
  audioCache: { [name: string]: HTMLAudioElement };
  setWait: (wait: boolean) => void;
  highlightElements: (refs: string[]) => void;
}) {
  const clips = useDynamicProperty(
    song.clips,
    useCallback(() => {
      const clipList = song.clips.value.clips.filter(
        (clip) =>
          clip.recordingId === chosenRecordingId &&
          clip.manifestationRefs.has(verseInfo.manifestationRef),
      );
      const clips: { default?: ClipInfo; ending?: ClipInfo } = {};
      for (const clip of clipList) {
        const type = clip.ending ? "ending" : "default";
        if (clips[type]) {
          alert(
            `Found multiple ${type} audio clips where one was expected: #${clip.id} #${
              clips[type]!.id
            }`,
          );
        }
        clips[type] = clip;
      }
      return clips;
    }, [chosenRecordingId, song, verseInfo]),
  );

  const dangling = !verseInfo.sectionInfo && !verseInfo.instrumental;
  const verseNumberOk = verseInfo.sectionInfo
    ? verseInfo.sectionInfo.verseNumbers.has(verseInfo.verseNumber || "")
    : !verseInfo.verseNumber;
  const labelIsUnique = verseInfo.instrumental || verseInfo.sectionInfo?.labelIsUnique;
  const manifestation = verseInfo.manifestation;

  return (
    <div
      className={
        "Section" + (dangling ? " dangling" : "") + (labelIsUnique ? "" : " duplicateLabel")
      }
    >
      {dangling && (
        <div className="error">
          This verse references a non-existing <code>&lt;section&gt;</code> id:{" "}
          <code>{manifestation.getAttribute("data")}</code>
          <br />
          <Button onClick={() => song.remove(verseInfo.manifestation)}>Remove this verse</Button>
        </div>
      )}
      {!verseNumberOk && verseInfo.verseNumber && (
        <div className="error">
          Verse number {verseInfo.verseNumber} is not present in the referenced{" "}
          <code>&lt;section&gt;</code> with id: <code>{manifestation.getAttribute("data")}</code>
        </div>
      )}
      {!verseNumberOk && !verseInfo.verseNumber && (
        <div className="error">
          Verse number required. Available verse numbers:{" "}
          {verseInfo.sectionInfo ? [...verseInfo.sectionInfo.verseNumbers].join(", ") : "?"}
        </div>
      )}
      {!dangling && !labelIsUnique && (
        <div className="warning">
          The label "{verseInfo.sectionInfo?.label}" is used by multiple
          <code>&lt;section&gt;</code>s, but it should be unique.
        </div>
      )}

      <div className="manifestation-edit">
        <h2 className="section-label">
          {verseInfo.label}
          {(!labelIsUnique || !verseInfo.label) && (
            <span className="reference">{manifestation.getAttribute("data")}</span>
          )}
        </h2>
        {!verseInfo.instrumental && (
          <TextField
            className="verse-number"
            label="Verse number"
            value={verseInfo.verseNumber}
            onChange={(e) => {
              manifestation.setAttribute("n", e.target.value);
            }}
          />
        )}

        <div className="section-edit-buttons">
          <IconButton
            title="duplicate verse"
            onClick={() => song.cloneTo(manifestation, manifestation, "afterend")}
          >
            <FileCopy />
          </IconButton>
          <IconButton title="delete verse" onClick={() => song.remove(manifestation)}>
            <Delete />
          </IconButton>
          <IconButton
            title="move verse down"
            onClick={() => MoveManifestation(verseInfo.manifestation, false)}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton
            title="move verse up"
            onClick={() => MoveManifestation(verseInfo.manifestation, true)}
          >
            <ArrowUpward />
          </IconButton>
        </div>
      </div>

      <div>
        <ClipChooser
          clip={clips.default}
          avFiles={avFiles}
          ending={false}
          song={song}
          verseInfo={verseInfo}
          chosenRecordingId={chosenRecordingId}
          setWait={setWait}
          highlightElements={highlightElements}
          audioCache={audioCache}
        />
        <ClipChooser
          clip={clips.ending}
          avFiles={avFiles}
          ending={true}
          song={song}
          verseInfo={verseInfo}
          chosenRecordingId={chosenRecordingId}
          setWait={setWait}
          highlightElements={highlightElements}
          audioCache={audioCache}
        />
      </div>
    </div>
  );
}
