import { Box, Button, IconButton, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import { Add, Delete, Edit } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import NamespaceResolver from "../lib/NamespaceResolver";
import { isoLanguages, sortedLanguages, useDynamicProperty } from "./utils";
import CanticoDomEditor, { RecordingInfo } from "../lib/CanticoDomEditor";

const NO_LANGUAGE = "-";

function displayLanguage(lang?: string) {
  return lang ? `${lang} (${isoLanguages[lang]})` : "instrumental (no language)";
}

export default function AudioVariantChooser({
  song,
  chosenRecordingId,
  setChosenRecordingId,
}: {
  song: CanticoDomEditor;
  chosenRecordingId: string;
  setChosenRecordingId: (id: string) => any;
}) {
  const recordings = useDynamicProperty(
    song.recordingsInfo,
    useCallback(() => {
      const newRecordings = song.recordingsInfo.value;
      const newRecordingId = newRecordings[chosenRecordingId]
        ? chosenRecordingId
        : Object.keys(newRecordings)[0] || "";
      setChosenRecordingId(newRecordingId);
      return newRecordings;
    }, [chosenRecordingId, song, setChosenRecordingId]),
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [recording, setRecording] = useState(
    recordings[chosenRecordingId] as RecordingInfo | undefined,
  );
  const [recordingName, setRecordingName] = useState(recording?.type || "");

  useEffect(() => setRecordingName(recording?.type || ""), [recording]);

  useEffect(() => {
    setRecording(recordings[chosenRecordingId]);
  }, [chosenRecordingId, recordings]);

  const modalStyle = {
    position: "absolute",
    bgcolor: "background.paper",
    verticalAlign: "bottom",
  };

  const addRecording = useCallback(() => {
    const recordingId = song.addRecording();
    new Promise((resolve) => song.recordingsInfo.observe(resolve)).then(() => {
      setChosenRecordingId(recordingId);
      setDialogOpen(true);
    });
  }, [setChosenRecordingId, song]);

  return (
    <div className="AudioVariantChooser leftsideBox">
      <div className="top-right-buttons">
        <IconButton title="Create new audio variant" onClick={addRecording}>
          <Add />
        </IconButton>
      </div>
      {Object.values(recordings).length > 0 && (
        <Select
          value={chosenRecordingId}
          inputProps={{ name: "audio variant" }}
          onChange={(e) => setChosenRecordingId(e.target.value as string)}
        >
          {Object.values(recordings).map(({ lang, type, id }) => (
            <MenuItem key={id} value={id}>
              {displayLanguage(lang)}: {type}
            </MenuItem>
          ))}
        </Select>
      )}
      <div className="audio-variant-buttons">
        {(() => {
          return (
            recording && (
              <>
                <IconButton
                  title="Edit audio variant properties"
                  onClick={() => setDialogOpen(true)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  title="Delete audio variant"
                  onClick={() => song.remove(recordings[chosenRecordingId].recording)}
                >
                  <Delete />
                </IconButton>

                <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
                  <Box sx={modalStyle}>
                    <div className="audio-variant-property-fields">
                      <Select
                        value={recording.lang || NO_LANGUAGE}
                        label="language"
                        inputProps={{ name: "language" }}
                        onChange={(e) => {
                          const lang = e.target.value as string;
                          if (lang && lang !== NO_LANGUAGE) {
                            // setAttributeNS requires qualified attribute name
                            recording.recording.setAttributeNS(
                              NamespaceResolver.XML_NS,
                              "xml:lang",
                              lang,
                            );
                          } else {
                            // removeAttributeNS requires local attribute name
                            recording.recording.removeAttributeNS(NamespaceResolver.XML_NS, "lang");
                          }
                        }}
                      >
                        <MenuItem key={NO_LANGUAGE} value={NO_LANGUAGE}>
                          {displayLanguage(undefined)}
                        </MenuItem>
                        {sortedLanguages.map((lang) => (
                          <MenuItem key={lang} value={lang}>
                            {displayLanguage(lang)}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        label="recording name"
                        value={recordingName}
                        onChange={(e) => {
                          setRecordingName(e.target.value);
                          recording.recording.setAttribute("type", e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    </div>
                  </Box>
                </Modal>
              </>
            )
          );
        })()}
      </div>
    </div>
  );
}
