type NameProperties = {
  prefix: string,
  localName: string,
  nodeName: string,
  /**
   * The namespace URI that the prefix resolves to for elements. The default
   * namespace for unprefixed element node names.
   */
  elementNamespaceURI: string,
  /**
   * The namespace URI that the prefix resolves to for attributes. Differs from
   * `elementNamespaceURI` if the default namespace is not the null namespace as
   * unprefixed attributes are always in the null namespace.
   */
  attributeNamespaceURI: string,
}

export default class NamespaceResolver {
  static readonly XML_NS = "http://www.w3.org/XML/1998/namespace";
  static readonly XLINK_NS = "http://www.w3.org/1999/xlink";

  #namespaceMap = {
    xlink: NamespaceResolver.XLINK_NS,
    xml: NamespaceResolver.XML_NS,
    "": "",
  } as { [key: string]: string };
  #cache = {} as { [key: string]: NameProperties };

  /**
   * Resolves a prefix to namespace URI.
   * Can also be used as namespace resolver function for `Document.evaluate()`
   * (or the `namespaceResolver` option of fontoxpath).
   */
  prefix: (prefix: string) => string;


  constructor(namespaceMap?: { [key: string]: string }) {
    Object.assign(this.#namespaceMap, namespaceMap);

    // Get the #namespaceMap bound to a closure so the prefix function can be
    // used standalone
    const namespaces = this.#namespaceMap;
    this.prefix = function prefix(prefix: string): string {
      const namespaceURI = namespaces[prefix];
      if (namespaceURI === undefined) {
        throw new Error(`Prefix "${prefix}" not bound to a namespace`);
      }
      return namespaceURI;
    }
  }

  addNamespace(prefix: string, namespaceURI: string) {
    const existingNamespace = this.#namespaceMap[prefix];
    if (existingNamespace && existingNamespace !== namespaceURI) {
      throw new Error(`Prefix "${prefix}" is already bound to ${existingNamespace}`);
    }
    this.#namespaceMap[prefix] = namespaceURI;
  }


  /**
   * @param nodeName A qualified name. ":" can be replaced with "$" for
   * convenience to allow valid JavaScript names.
   */
  nodeName(nodeName: string): NameProperties {
    if (this.#cache[nodeName]) {
      return this.#cache[nodeName];
    }

    const normalizedNodeName = nodeName.replace("$", ":");
    if (this.#cache[normalizedNodeName]) {
      return this.#cache[nodeName] = this.#cache[normalizedNodeName];
    }

    const [, , prefix, localName] = nodeName.match(/^(([^:]+)[:$])?([^:!?]+)$/) || [];

    if (localName === undefined) {
      throw new Error(`Malformed node name "${nodeName}"`);
    }
    const namespaceURI = this.prefix(prefix || "");

    return this.#cache[nodeName] = this.#cache[normalizedNodeName] = Object.freeze({
      // Nodes in the null namespace must be unprefixed. Also, attributes can't
      // have a namespace without a prefix.
      // In templates, we however allow prefixes that are mapped to the null
      // namespace. This makes it easier to have a default namespace that is not
      // the null namespace and still be able to add nodes in the null
      // namespace.
      prefix: (namespaceURI && prefix) || "",
      localName,
      nodeName: normalizedNodeName,
      elementNamespaceURI: namespaceURI || "",
      attributeNamespaceURI: (prefix && namespaceURI) || "",
    });
  }
}
