import "./App.scss";
import IoButtons from "./components/IoButtons";
import MetadataEditor from "./components/MetadataEditor";
import AudioVariantChooser from "./components/AudioVariantChooser";
import SectionEditor from "./components/SectionEditor";
import Verovio from "./components/Verovio";
import XmlEditor from "./components/XmlEditor";
import SplitPane from "react-split-pane";
import { useCallback, useEffect, useState } from "react";
import CanticoDomEditor from "./lib/CanticoDomEditor";
import { File } from "./lib/GitHub";
import { Button, CircularProgress, DialogContent, Modal } from "@material-ui/core";
import config from "./config/config.json";
import { ACCESS_TOKEN, authenticate } from "./components/authenticate";

const clientId = (config.clientId as { [host: string]: string })[window.location.hostname];
if (!clientId) {
  alert("Configuration error: No configuration available for host " + window.location.hostname);
} else {
  authenticate(clientId);
}

function App() {
  const [accessToken, setAccesToken] = useState(localStorage.getItem("accessToken") || "");
  const [song, setSong] = useState(undefined as CanticoDomEditor | undefined);
  const [focusedMeiId, setFocusedMeiId] = useState("");
  const [chosenRecordingId, setChosenRecordingId] = useState(
    (song && Object.values(song.recordingsInfo.value)[0]?.id) || "",
  );
  const [avFiles, setAvFiles] = useState([] as File[]);
  const [wait, setWait] = useState(false);

  useEffect(
    () => setChosenRecordingId(song ? Object.values(song.recordingsInfo.value)[0]?.id : ""),
    [song],
  );

  // We get the access token from persisten localStorage, but storage events
  // don't seem to be firing at a point where we can catch them, so resort to
  // polling.
  // I kept the authentication independent of React, bridging them with
  // localStorage because I did not find a good way where there were no issues
  // with React calling the authentication mutliple times or where concurrency
  // got out of hand. Sorry!
  const readAccessToken = useCallback(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      setAccesToken(accessToken);
    } else {
      window.setTimeout(readAccessToken, 300);
    }
  }, []);
  if (!accessToken) {
    readAccessToken();
  }

  const highlightElements = useCallback((selectors: string[]) => {
    const verovioHighlightStyle = document.querySelector("#verovioHighlightStyle") as Element;
    if (selectors.length === 0) {
      verovioHighlightStyle.textContent = "";
    } else {
      verovioHighlightStyle.textContent = selectors.join(",") + "{stroke: red; fill: red;}";
      try {
        document.querySelector(selectors[0])?.closest(".staff")?.scrollIntoView();
      } catch (e) {}
    }
  }, []);

  return (
    <div className="App">
      <header className="appHeader">
        Cantico Bundle Editor
        <Button className="help" href="help.html" target="_blank">
          Help page
        </Button>
      </header>
      <SplitPane split="vertical" className="splitPane">
        <div className="sidebar">
          <IoButtons
            accessToken={accessToken}
            setAccessToken={setAccesToken}
            song={song}
            setSong={setSong}
            setAvFiles={setAvFiles}
            setWait={setWait}
          />
          {song && (
            <>
              <MetadataEditor song={song} />
              <AudioVariantChooser
                song={song}
                chosenRecordingId={chosenRecordingId}
                setChosenRecordingId={setChosenRecordingId}
              />
              <SectionEditor
                song={song}
                chosenRecordingId={chosenRecordingId}
                avFiles={avFiles}
                setWait={setWait}
                highlightElements={highlightElements}
              />
            </>
          )}
        </div>
        <div className="content">
          <SplitPane split="horizontal">
            <div>
              <style id="verovioHighlightStyle" />
              <Verovio
                song={song}
                focusedMeiId={focusedMeiId}
                setFocusedMeiId={setFocusedMeiId}
                highlightElements={highlightElements}
              />
            </div>
            <XmlEditor
              song={song}
              setSong={setSong}
              focusedMeiId={focusedMeiId}
              setFocusedMeiId={setFocusedMeiId}
            />
          </SplitPane>
        </div>
      </SplitPane>
      <Modal open={wait} className="wait">
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Modal>
    </div>
  );
}

export default App;
